import React, { useState, ChangeEvent } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Heading,
  Text,
  InputRightElement,
  InputGroup,
  useToast,
  Icon,
  Flex,
  useColorModeValue,
  useTheme,
  useColorMode,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

import { isPasswordValid } from 'views/auth/helpers';
import { GetGradients } from 'utils/gradients';
import { axiosClient } from 'api/axios';
import { hexToRgba } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';
import { BsPatchCheckFill } from 'react-icons/bs';

export default function ResetPasswordForm() {
  // Hooks
  const toast = useToast();
  const navigate = useNavigate()
  const { colors } = useTheme();

  // States
  const [stage, setStage] = useState(1); // 1: Forgot Password, 2: Verification Code, 3: Reset Password
  const [formData, setFormData] = useState({
    username: '',
    newPassword: '',
    confirmNewPassword: '',
    verificationCode: '',
  });
  const [submitting, setSubmitting] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [resetSuccess, setResetSuccess] = useState<null | string>(null);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  // Theme
  const focusBg = useColorModeValue('gray.100', 'gray.700');
  const { appTitleGradient } = GetGradients();
  const { setColorMode } = useColorMode();

  setColorMode('light');

  // Handlers
  const handleInputsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setErrorMsg('');
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePasswordToggle = (pass: 'PASSWORD' | 'CONFIRM_PASSWORD') => {
    if (pass === 'PASSWORD') {
      setShowPassword({
        ...showPassword,
        password: !showPassword.password,
      });
    } else {
      setShowPassword({
        ...showPassword,
        confirmPassword: !showPassword.confirmPassword,
      });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    setErrorMsg('');
    const { username, newPassword, confirmNewPassword, verificationCode } =
      formData;
    let response = null;
    let message = '';

    try {
      if (stage === 1) {
        if (!username) return;

        // Request to send verification code
        response = await axiosClient.post(
          '/api/admin_forgot_password',
          JSON.stringify({ username })
        );

        message =
          'Verification code sent successfully. Please check your email.';
        setStage(2);
      } else if (stage === 2) {
        setIsVerifying(true);
        // Verify the code
        if (!verificationCode || verificationCode.length !== 6) {
          setErrorMsg('Invalid verification code');
          setIsVerifying(false);
          return;
        }
        const payload = { verificationCode, username };

        await axiosClient.post(
          `/api/verify_verification_code`,
          payload
        ).then((res) => {
          setIsVerifying(false);
          setStage(3);
        })
          .catch((error) => {
            console.log(error);
            toast({
              description: error.response.data.message
                ? error.response.data.message
                : "An error occurred while verifying code",
              status: "error",
              position: "top-right",
            });
            setIsVerifying(false);
          });
      } else {
        //? Resetting new password
        if (!username || !newPassword) return;

        const passwordError = isPasswordValid(newPassword, confirmNewPassword);
        if (passwordError) {
          setErrorMsg(passwordError);
          setSubmitting(false);
          return;
        }

        response = await axiosClient.post(
          '/api/reset_password',
          JSON.stringify({
            username,
            newPassword,
            confirmNewPassword
          })
        );

        setResetSuccess(response.data)
        setTimeout(() => {
          navigate('/login', { replace: true })
        }, 3000)
      }
      message &&
        toast({
          position: 'bottom-right',
          duration: 3000,
          render: () => (
            <Box
              color="white"
              p={3}
              bg={'highlight.primary'}
              borderRadius={'6px'}
            >
              {message}
            </Box>
          ),
        });
    } catch (error: any) {
      if (!error.response) {
        toast({
          description: 'No server response!',
          status: 'error',
        });
        return;
      }
      toast({
        description: error.response.data.message,
        status: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  const renderInputs1 = (
    <FormControl id="username" w="100%">
      <FormLabel fontSize={'14px'} color={'gray.600'} lineHeight={'1.3'}>
        Username
      </FormLabel>
      <Input
        type="text"
        name="username"
        placeholder="Enter your username"
        _placeholder={{ fontSize: '14px' }}
        value={formData.username}
        onKeyDown={handleKeyDown}
        onChange={handleInputsChange}
        width="100%"
        _focus={{
          outline: 'none',
          boxShadow: 'none',
          bg: focusBg,
        }}
      />
    </FormControl>
  );

  const renderInputs2 = (
    <FormControl id="verificationCode">
      <FormLabel>Verification Code</FormLabel>
      <Input
        type="text"
        name="verificationCode"
        placeholder="Enter verification code"
        value={formData.verificationCode}
        onChange={handleInputsChange}
        onKeyDown={handleKeyDown}
        width="100%"
        _focus={{
          outline: 'none',
          boxShadow: 'none',
          bg: focusBg,
        }}
      />
    </FormControl>
  );

  const renderInputs3 = (
    <>
      <FormControl id="newPassword" w="100%">
        <FormLabel>New Password</FormLabel>
        <InputGroup>
          <Input
            type={showPassword.password ? 'text' : 'password'}
            name="newPassword"
            placeholder="Enter your new password"
            value={formData.newPassword}
            onChange={handleInputsChange}
          />
          <InputRightElement>
            <Icon
              as={showPassword.password ? ViewOffIcon : ViewIcon}
              name="password"
              color="secondary.300"
              cursor="pointer"
              onClick={() => handlePasswordToggle('PASSWORD')}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl id="confirmNewPassword" w="100%">
        <FormLabel>Confirm New Password</FormLabel>
        <InputGroup>
          <Input
            type={showPassword.confirmPassword ? 'text' : 'password'}
            name="confirmNewPassword"
            placeholder="Confirm your new password"
            value={formData.confirmNewPassword}
            onKeyDown={handleKeyDown}
            onChange={handleInputsChange}
          />
          <InputRightElement>
            <Icon
              as={showPassword.confirmPassword ? ViewOffIcon : ViewIcon}
              name="confirmPassword"
              color="secondary.300"
              cursor="pointer"
              onClick={() => handlePasswordToggle('CONFIRM_PASSWORD')}
            />
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </>
  );

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      gap={4}
      p={10}
      borderRadius={'10px'}
      bg={hexToRgba(colors.neutral[100], 0.95)}
      boxShadow="0 8px 32px 0 rgba( 31, 38, 80, 0.37 )"
      backdropFilter="blur( 4.5px )"
      border="1px solid rgba( 255, 255, 255, 0.18 )"
    >
      <Heading fontSize="26px" color={'gray.600'} mb={'30px'}>
        Welcome to{' '}
        <Text
          as="span"
          fontWeight={'bold'}
          bgGradient={appTitleGradient}
          bgClip="text"
        >
          Lexai
        </Text>
      </Heading>
      <Text fontSize="sm" color="black" opacity={0.85}>
        {resetSuccess ? 'You will be redirected to login page.' : stage === 2
          ? "Enter verification code"
          : stage === 3
            ? "Choose another password"
            : "Please enter your username or email"}
      </Text>
      {
        resetSuccess ?
          <Flex
            w="100%"
            bg="green.100"
            color="green.700"
            p={2}
            borderRadius="md"
            alignItems="center"
          >
            <Icon as={BsPatchCheckFill} boxSize={5} mr={3} />
            <Text fontWeight="medium">
              {resetSuccess}
            </Text>
          </Flex>
          :
          <>
            {/* Inputs */}
            {stage === 2
              ? renderInputs2
              : stage === 3
                ? renderInputs3
                : renderInputs1}

            {/* Error message */}
            {errorMsg && (
              <Text fontSize="sm" color="jasper">
                {errorMsg}
              </Text>
            )}

            {/* Submit button */}
            <Button
              colorScheme="secondary"
              size="md"
              onClick={handleSubmit}
              w="100%"
              isLoading={submitting || isVerifying}
              loadingText="Processing"
            >
              {stage === 2
                ? "Continue"
                : stage === 3
                  ? "Reset Password"
                  : "Get Verification Code"}
            </Button>
          </>
      }
    </Flex>
  );
}
