import { Flex, Text } from '@chakra-ui/react';
import { User } from 'models/Users';

interface StatsUsersProps {
  allUsers: User[];
  filteredUsers: User[];
}

function StatsUsers({ allUsers, filteredUsers }: StatsUsersProps) {
  return (
    <Flex gap={4} align={'center'}>
      {/* Total nbr of users */}
      {!!allUsers?.length && (
        <Text
          fontWeight={'500'}
          fontSize={'14px'}
          lineHeight={1}
          color={'gray.500'}
        >
          Total:{' '}
          <Text as={'span'} fontWeight={'400'}>
            {allUsers?.length}
          </Text>
        </Text>
      )}

      {/* Results */}
      {!!filteredUsers?.length &&
        filteredUsers?.length !== allUsers?.length && (
          <Text
            fontWeight={'500'}
            fontSize={'14px'}
            lineHeight={1}
            color={'gray.500'}
          >
            Result{filteredUsers?.length !== 1 ? 's' : ''}:{' '}
            <Text as={'span'} fontWeight={'400'}>
              {filteredUsers?.length}
            </Text>
          </Text>
        )}
    </Flex>
  );
}

export default StatsUsers;
