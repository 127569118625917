import { Button, Flex, Td } from '@chakra-ui/react';
import { User } from 'models/Users';

interface ActionButtonsProps {
  user: User;
  onOpenEditModal: (u: User) => void;
  onOpenDeleteModal: (u: User) => void;
}

export default function ActionButtons({
  user,
  onOpenEditModal,
  onOpenDeleteModal,
}: ActionButtonsProps) {
  return (
    <Td border={'none'} minH={'72px'}>
      <Flex gap={1}>
        <Button
          size="sm"
          bg={'transparent'}
          borderWidth={1}
          borderColor={'transparent'}
          color={'blue.500'}
          _hover={{ borderColor: 'blue.500' }}
          onClick={event => {
            event.stopPropagation();
            onOpenEditModal(user);
          }}
        >
          Edit
        </Button>
        <Button
          size="sm"
          bg={'transparent'}
          borderWidth={1}
          borderColor={'transparent'}
          color={'red.500'}
          _hover={{ borderColor: 'red.500' }}
          onClick={event => {
            event.stopPropagation();
            onOpenDeleteModal(user);
          }}
        >
          Delete
        </Button>
      </Flex>
    </Td>
  );
}
