import React, { useState, ChangeEvent } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Heading,
  Text,
  InputRightElement,
  InputGroup,
  useToast,
  Icon,
  Flex,
  useColorModeValue,
  useTheme,
  useColorMode,
  Link,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { isPasswordValid } from "views/auth/helpers";
import { GetGradients } from "utils/gradients";
import { axiosClient } from "api/axios";
import { useDispatch } from "react-redux";
import { hexToRgba } from "utils/helpers";
import { setAccessToken, setRefreshToken, setUser } from "store/features/auth/authSlice";

export default function LoginForm() {
  // Hooks
  const toast = useToast();
  const dispatch = useDispatch();
  const { colors } = useTheme();

  // States
  const [submitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [changePassword, setChangePassword] = useState(false);
  const [resetPasswordSession, setResetPasswordSession] = useState(null);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  });
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [passwordForm, setPasswordForm] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  // Theme
  const focusBg = useColorModeValue("gray.100", "gray.700");
  const { appTitleGradient } = GetGradients();
  const { setColorMode } = useColorMode();

  setColorMode("light");

  // Handlers
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setErrorMsg("");
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setErrorMsg("");
    setPasswordForm({
      ...passwordForm,
      [name]: value,
    });
  };

  const handlePasswordToggle = (pass: 'PASSWORD' | 'CONFIRM_PASSWORD') => {
    if (pass === 'PASSWORD') {
      setShowPassword({
        ...showPassword,
        password: !showPassword.password
      });
    } else {
      setShowPassword({
        ...showPassword,
        confirmPassword: !showPassword.confirmPassword
      });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  // form submit
  const handleSubmit = async () => {
    setResetPasswordSession(null);
    try {
      const newPassword = passwordForm.newPassword;
      const confirmNewPassword = passwordForm.confirmNewPassword;
      const username = formData.username;
      const password = formData.password;

      let response = null;

      if (changePassword) {
        //? Resetting new password
        if (!username || !newPassword) return;

        const passwordError = isPasswordValid(newPassword, confirmNewPassword);

        if (passwordError.length > 0) {
          setErrorMsg(passwordError);
          setSubmitting(false);
          return;
        }

        setSubmitting(true);
        response = await axiosClient.post(
          "/api/admin_new_password_required",
          JSON.stringify({ username, newPassword, resetPasswordSession })
        );
      } else {
        //? Login
        if (!username || !password) return;

        setSubmitting(true);
        response = await axiosClient.post(
          "/api/admin_auth",
          JSON.stringify({ username, password })
        );
      }
      const { user, accessToken, refreshToken } = response?.data;
      dispatch(setUser(user));
      dispatch(setAccessToken(accessToken));
      dispatch(setRefreshToken(refreshToken));

      toast({
        position: "bottom-right",
        duration: 1500,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Logged in successfully
          </Box>
        ),
      });
    } catch (error: any) {
      // No response from server
      if (!error.response) {
        toast({
          description: "No server response!",
          status: "error",
        });
        return;
      }

      if (error.response.data.message === "NEW_PASSWORD_REQUIRED") {
        toast({
          description: "Please reset your password",
          status: "info",
          position: "top-right",
        });

        //! Redirect user to reset PASSWORD page (Later)
        setChangePassword(true);
        setResetPasswordSession(error.response.data.session);
      } else {
        toast({
          description: error.response.data.message,
          status: "error",
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Flex
      direction="column"
      justify="center"
      align="left"
      gap={4}
      p={10}
      borderRadius={"10px"}
      bg={hexToRgba(colors.neutral[100], 0.95)}
      boxShadow="0 8px 32px 0 rgba( 31, 38, 80, 0.37 )"
      backdropFilter="blur( 4.5px )"
      border="1px solid rgba( 255, 255, 255, 0.18 )"
    >
      <Heading fontSize="14px" color={"gray.600"} mb={0}>
        <Text
          as="span"
          fontWeight={"bold"}
          bgGradient={appTitleGradient}
          bgClip="text"
        >
          Lexai
        </Text>
        {" "}Admin Dashboard
      </Heading>

      <Heading fontSize="26px" mb={"12px"} color={hexToRgba(colors.black, 0.7)}>
        Sign in to your account
      </Heading>

      {!changePassword ? (
        <>
          <FormControl id="username">
            <FormLabel fontSize={"14px"} color={"gray.600"} lineHeight={"1.3"}>
              Username
            </FormLabel>
            <Input
              type="text"
              name="username"
              placeholder="Enter your username"
              _placeholder={{ fontSize: "14px" }}
              value={formData.username}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              width="100%"
              _focus={{
                outline: "none",
                boxShadow: "none",
                bg: focusBg,
              }}
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel fontSize={"14px"} color={"gray.600"} lineHeight={"1.3"}>
              Password
            </FormLabel>
            <InputGroup>
              <Input
                type={showPassword.password ? "text" : "password"}
                name="password"
                placeholder="Enter your password"
                _placeholder={{ fontSize: "14px" }}
                value={formData.password}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                width="100%"
                _focus={{
                  outline: "none",
                  boxShadow: "none",
                  bg: focusBg,
                }}
              />
              <InputRightElement>
                <Icon
                  as={showPassword.password ? ViewOffIcon : ViewIcon}
                  color={"secondary.400"}
                  cursor={"pointer"}
                  onClick={() => handlePasswordToggle('PASSWORD')}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl>
            <Flex justify={"end"} align={"center"} maxW={"full"}>
              <Link
                color={"primary.400"}
                fontSize={"12px"}
                lineHeight={"1"}
                href="/reset-password"
              >
                Forgot password?
              </Link>
            </Flex>
          </FormControl>
          <Button
            bg={"secondary.400"}
            color={"secondary.100"}
            size="md"
            w="100%"
            type="submit"
            isLoading={submitting}
            loadingText="Processing"
            onClick={handleSubmit}
            mt={6}
            _hover={{ bg: "secondary.500" }}
          >
            Login
          </Button>
        </>
      ) : (
        <>
          <Text fontSize="sm" color="brand.secondary700">
            Please change your password
          </Text>
          <FormControl id="newPassword" w="100%">
            <FormLabel>New Password</FormLabel>
            <InputGroup>
              <Input
                type={showPassword.password ? "text" : "password"}
                name="newPassword"
                placeholder="Enter your new password"
                value={passwordForm.newPassword}
                onChange={handleInputPasswordChange}
              />
              <InputRightElement>
                <Icon
                  // aria-label={showPassword.password ? "Hide Password" : "Show Password"}
                  as={showPassword.password ? ViewOffIcon : ViewIcon}
                  color={"secondary.300"}
                  cursor={"pointer"}
                  onClick={() => handlePasswordToggle('PASSWORD')}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl id="confirmNewPassword" w="100%">
            <FormLabel>Confirm New Password</FormLabel>
            <InputGroup>
              <Input
                type={showPassword.confirmPassword ? "text" : "password"}
                name="confirmNewPassword"
                placeholder="Confirm your new password"
                value={passwordForm.confirmNewPassword}
                onChange={handleInputPasswordChange}
              />
              <InputRightElement>
                <Icon
                  aria-label={showPassword.confirmPassword ? "Hide Password" : "Show Password"}
                  as={showPassword.confirmPassword ? ViewOffIcon : ViewIcon}
                  color={"secondary.300"}
                  cursor={"pointer"}
                  onClick={() => handlePasswordToggle('CONFIRM_PASSWORD')}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Text fontSize="sm" color="jasper">
            {errorMsg}
          </Text>
          <Button
            colorScheme="secondary"
            size="md"
            onClick={handleSubmit}
            w="100%"
            isLoading={submitting}
            loadingText="Processing"
          >
            Reset Password
          </Button>
        </>
      )}
    </Flex>
  );
}
