import { useDispatch, useSelector } from "react-redux"
import { clearCredentials, selectCurrentAuthData } from "store/features/auth/authSlice"
import { axiosClient } from "api/axios"
import { Box, useToast } from "@chakra-ui/react";

export default function useLogout() {
    const dispatch = useDispatch()
    const toast = useToast();
    const { refreshToken } = useSelector(selectCurrentAuthData)

    const logout = async () => {
        try {
            await axiosClient.post('/api/logout', { refreshToken });
            toast({
                position: "bottom-right",
                duration: 1500,
                render: () => (
                    <Box
                        color="white"
                        p={3}
                        bg={"highlight.primary"}
                        borderRadius={"6px"}
                    >
                        Logged out successfully
                    </Box>
                ),
            });
        } catch (error) {
            // console.log(error)
        } finally {
            dispatch(clearCredentials())
        }
    }

    return logout
}