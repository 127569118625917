import { Link as RouterLink } from "react-router-dom";
import { Icon, Link, Tooltip, useColorMode, useTheme } from "@chakra-ui/react";

import { ItemProps } from ".";
import { hexToRgba } from "utils/helpers";

export interface NavItemProps {
  item: ItemProps;
  active: string;
  setActive: (s: string) => void;
}

export default function NavItem({ item, active, setActive }: NavItemProps) {
  // Theme
  const { colorMode } = useColorMode();
  const { colors } = useTheme();

  const { name, link, tooltip, icon } = item;
  const isLightTheme = colorMode === "light";
  const isActive = active === name;
  const activeColor = isActive
    ? "secondary.400"
    : isLightTheme
    ? hexToRgba(colors.neutral[500], 0.8)
    : hexToRgba(colors.neutral[100], 0.9);

  return (
    <Tooltip label={tooltip} placement="right">
      <Link
        h={"100%"}
        as={RouterLink}
        to={link}
        onClick={() => setActive(name)}
      >
        <Icon
          as={icon}
          color={activeColor}
          boxSize={[null, null, null, "20px", "22px", "24px"]}
          h={"100%"}
        />
      </Link>
    </Tooltip>
  );
}
