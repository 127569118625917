import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useUsersAPI } from './useUsersAPI';
import { useToast, Box } from '@chakra-ui/react';
import { errorHandler } from 'utils/helpers';
import { selectCurrentAuthData } from 'store/features/auth/authSlice';
import { useSelector } from 'react-redux';

export const useUpdateUserMutation = () => {
  const { user } = useSelector(selectCurrentAuthData);
  const { updateUser, saveAdminActivity } = useUsersAPI();
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ payload, selectedUser }: { payload: any, selectedUser: any }) => {
      const res = await updateUser(payload, selectedUser?.cognito_sub);

      const prevUserType = selectedUser?.user_type || '';
      const activity = {
        user_id: user.id,
        activity:
          prevUserType !== payload.user_type
            ? `Updated user type from ${selectedUser?.user_type} to ${payload?.user_type}: ${selectedUser?.email}`
            : `Updated user firstname::lastname: ${selectedUser?.email}`,
      };

      await saveAdminActivity(activity);

      return res;
    },
    onSuccess: () => {

      toast({
        position: 'bottom-right',
        duration: 3000,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={'highlight.primary'}
            borderRadius={'6px'}
          >
            Successfully updated the user info
          </Box>
        ),
      });

      queryClient.refetchQueries({ queryKey: ['users'] })
      queryClient.refetchQueries({ queryKey: ['histories'] })
    },
    onError: (error) => {
      toast({
        description: errorHandler(error).message,
        status: 'error',
        position: 'top-right',
      });
    }
  });
};