import { User } from 'models/Users';

export const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidName = (name: string) => {
  const nameRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ-]+(?:-[a-zA-ZÀ-ÖØ-öø-ÿ]+)*$/;
  return nameRegex.test(name);
};

export const titleCase = (str: string) => {
  return str?.toLowerCase()?.replace(/\b\S/g, function (t) {
    return t?.toUpperCase();
  });
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};


// Define sorting functions
export function byName(a: User, b: User) {
  const a_name: string | undefined = a?.given_name?.toLowerCase();
  const b_name: string | undefined = b?.given_name?.toLowerCase();

  if (a_name && b_name) {
    return a_name.localeCompare(b_name);
  }
  return 1;
}

// Sorting functions for `type` column
export function byType(a: User, b: User) {
  const order = ['free', 'basic', 'premium', 'teams', 'no data'];
  const typeA = a.user_type || 'no data';
  const typeB = b.user_type || 'no data';
  const indexA = order.indexOf(typeA);
  const indexB = order.indexOf(typeB);
  return indexA - indexB;
}

// Sorting functions for `status` column
export function byStatus(a: User, b: User) {
  const order = ['inactive', 'active'];
  const is_a_active = a.user_status === 'CONFIRMED' && a.enabled;
  const is_b_active = b.user_status === 'CONFIRMED' && b.enabled;

  const statusA = is_a_active ? 'active' : 'inactive';
  const statusB = is_b_active ? 'active' : 'inactive';
  const indexA = order.indexOf(statusA);
  const indexB = order.indexOf(statusB);

  return indexA - indexB;
}

// Sorting functions for `invited at` column
// Recent to oldest
export function byDate(a: User, b: User) {
  return new Date(b.invited_at).getTime() - new Date(a.invited_at).getTime();
}

export const filterUsers = (users: User[], filter: string) => {
  if (filter === 'new') {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    users = users?.filter(
      (user: User) =>
        user?.first_login && new Date(user.first_login) >= oneMonthAgo
    );
  } else if (filter === 'active') {
    users = users?.filter((user: User) => {
      if (!user?.last_login) return false;
      else {
        const lastLogin = new Date(user?.last_login);
        const currentDate: Date = new Date();

        // Calculate the difference in milliseconds
        const differenceInMilliseconds: number =
          currentDate.getTime() - lastLogin.getTime();

        // Convert milliseconds to weeks
        const millisecondsInWeek: number = 7 * 24 * 60 * 60 * 1000;
        const differenceInWeeks: number =
          differenceInMilliseconds / millisecondsInWeek;

        const isWithinOneWeek = differenceInWeeks < 1;

        return (
          user.user_status === 'CONFIRMED' && user.enabled && isWithinOneWeek
        );
      }
    });
  } else if (filter === 'premium') {
    users = users.filter(user => user.user_type === 'premium');
  } else if (filter === 'teams') {
    users = users.filter(user => user.user_type === 'teams');
  } else if (filter === 'basic') {
    users = users.filter(user => user.user_type === 'basic');
  } else if (filter === 'free') {
    users = users.filter(user => user.user_type === 'free');
  }

  return users;
};
