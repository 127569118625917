import { ReactNode } from 'react';
import {
  Icon,
  Input,
  InputProps,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';

type SearchInputProps = InputProps & { rightComponent?: ReactNode };

function SearchUsers({ rightComponent = <></>, ...props }: SearchInputProps) {
  const bg = useColorModeValue('neutral.100', 'dark.700');
  const color = useColorModeValue('gray.600', 'gray.200');
  const placeholder = useColorModeValue('gray.500', 'gray.300');
  const border = useColorModeValue('gray.400', 'gray.600');
  const shadow = useColorModeValue('0px 1px 4px rgba(0, 0, 0, 0.05)', 'none');

  return (
    <InputGroup
      borderRadius={30}
      borderColor={border}
      borderWidth={1}
      w={'200px'}
      bg={bg}
      boxShadow={shadow}
    >
      <InputLeftElement pointerEvents="none">
        <Icon as={FaSearch} w={4} h={4} borderRadius={'50%'} color={border} />
      </InputLeftElement>
      <Input
        type="text"
        fontSize={'14px'}
        color={color}
        borderRadius={20}
        outline={'none'}
        border={'none'}
        _focusVisible={{
          shadow: 'none',
          outline: 'none',
          border: 'none',
        }}
        placeholder="search by name"
        sx={{
          '::placeholder': {
            fontSize: '14px',
            color: placeholder,
            position: 'absolute',
            top: '45%',
            transform: 'translateY(-50%)',
            left: '40px',
          },
        }}
        {...props}
      />
      {rightComponent}
    </InputGroup>
  );
}

export default SearchUsers;
