import { Box, Icon, useColorModeValue, useTheme } from '@chakra-ui/react';
import { IoFilterSharp } from 'react-icons/io5';
import Select, {
  ActionMeta,
  SingleValue,
  StylesConfig,
  components,
} from 'react-select';
import { hexToRgba } from 'utils/helpers';
import { ColourOption } from '../UsersTable';
import { FaTimes } from 'react-icons/fa';

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 20,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

interface SelectUsersProps {
  selected: ColourOption | null;
  onSelectChange:
    | ((
        newValue: SingleValue<ColourOption>,
        actionMeta: ActionMeta<ColourOption>
      ) => void)
    | undefined;
  onClearSelection: () => void;
}

function SelectUsers({
  selected,
  onSelectChange,
  onClearSelection,
}: SelectUsersProps) {
  // Theme
  const { colors } = useTheme();
  const controlBg = useColorModeValue(colors.neutral[100], colors.dark[700]);
  const optionBg = useColorModeValue('transparent', colors.dark[700]);
  const border = useColorModeValue(colors.gray[400], colors.gray[600]);
  const placeholder = useColorModeValue(colors.gray[500], colors.gray[300]);
  const color = useColorModeValue(colors.gray[600], colors.gray[200]);
  const shadow = useColorModeValue('0px 1px 4px rgba(0, 0, 0, 0.05)', 'none');

  const colourOptions = [
    { color: colors.green[500], value: 'active', label: 'Active' },
    { color: colors.yellow[500], value: 'premium', label: 'Premium' },
    { color: colors.pink[500], value: 'teams', label: 'Teams' },
    { color: colors.blue[500], value: 'basic', label: 'Basic' },
    { color: colors.gray[500], value: 'free', label: 'Free' },
    { color: 'transparent', value: 'new', label: 'New Users' },
  ];

  // Styles
  const placeholderStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: placeholder,
  };

  const colourStyles: StylesConfig<ColourOption> = {
    menuList: styles => ({
      ...styles,
      padding: 0,
    }),
    control: styles => ({
      ...styles,
      backgroundColor: controlBg,
      fontSize: '14px',
      width: '170px',
      height: '42px',
      borderRadius: '30px',
      borderColor: border,
      borderWidth: 1,
      boxShadow: shadow,
    }),
    option: (styles, { data, isSelected }) => {
      return {
        ...styles,
        fontSize: '14px',
        fontWeight: isSelected ? '500' : '400',
        backgroundColor: isSelected ? hexToRgba(data.color, 0.1) : optionBg,
        color: isSelected && data.color !== 'transparent' ? data.color : color,
        cursor: 'pointer',
        ':active': {
          ...styles[':active'],
          backgroundColor: hexToRgba(data.color, 0.1),
        },
      };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({
      ...styles,
      fontSize: '14px',
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      color,
      ...dot(data.color),
    }),
  };

  return (
    <Select
      placeholder={
        <div style={placeholderStyle}>
          <Icon as={IoFilterSharp} w={4} h={4} borderRadius={'50%'} />
          filter by type
        </div>
      }
      styles={colourStyles}
      options={colourOptions}
      closeMenuOnSelect={true}
      value={selected}
      onChange={onSelectChange}
      isMulti={false}
      isSearchable={false}
      isClearable={true}
      components={{
        IndicatorSeparator: () => null,

        DropdownIndicator: props => {
          return props.selectProps.value ? null : (
            <components.DropdownIndicator {...props} />
          );
        },

        ClearIndicator: props => {
          return (
            <components.ClearIndicator {...props}>
              <Box onMouseDown={onClearSelection}>
                {props.selectProps.value && <FaTimes />}
              </Box>
            </components.ClearIndicator>
          );
        },
      }}
    />
  );
}

export default SelectUsers;
