import ResetPasswordForm from "components/authforms/ResetPasswordForm"
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCurrentAuthData } from "store/features/auth/authSlice";

const ResetPasswordView = () => {
    const navigate = useNavigate();

    const { user } = useSelector(selectCurrentAuthData);

    useEffect(() => {
        if (user) navigate('/')
    }, [user, navigate]);

    return user ? null : <ResetPasswordForm />;
}
export default ResetPasswordView