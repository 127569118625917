import { useMemo } from 'react';
import {
  useBreakpointValue,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react';
import ReactECharts from 'echarts-for-react';
import { ChartItemProps } from 'views/home';
import { hexToRgba } from 'utils/helpers';
import { chartHeight } from 'utils/responsive';

interface UsersChartProps {
  allUsers: ChartItemProps;
  activeUsers: ChartItemProps;
  newUsers: ChartItemProps;
  preparingChart: boolean;
}

// Helper: for each date, it checks if it exists in data.
// if it doesn't exist, it adds currentDate to data
// with the value from the prev date.
function preProcessData(data: ChartItemProps, dates: string[]) {
  for (let i = 1; i < dates.length; i++) {
    const currentDate = dates[i];
    const previousDate = dates[i - 1];

    if (!data[currentDate]) {
      data[currentDate] = data[previousDate];
    }
  }

  return data;
}

function UsersChart({
  allUsers,
  activeUsers,
  newUsers,
  preparingChart,
}: UsersChartProps) {
  // Theme
  const { colors } = useTheme();
  const themeColor = useColorModeValue(colors.gray[500], colors.gray[300]);
  const loadingColor = useColorModeValue(
    colors.primary[800],
    colors.primary[300]
  );

  // datasets
  const allUsersDataset = Object.values(allUsers);
  const activeUsersDataset = Object.values(
    preProcessData(activeUsers, Object.keys(allUsers))
  ).sort((a, b) => a - b);
  const newUsersDataset = Object.values(newUsers);

  const responsiveChartHeight = useBreakpointValue(chartHeight);

  // Style
  const chartStyle = {
    width: '100%',
    height: responsiveChartHeight,
    margin: 'auto',
  };

  // chart configuration
  const chartOptions = {
    animation: true,
    animationDuration: 1000,
    animationEasingUpdate: 'quinticInOut',
    tooltip: {
      trigger: 'axis',
      position: (pt: any) => [pt[0], '10%'],
      confine: true, // replaces custom positioning. assigns dynamic position to prevent items overflow
    },
    xAxis: {
      show: !preparingChart,
      type: 'category',
      boundaryGap: true,
      data: Object.keys(allUsers),

      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        rotate: 45,
        margin: 12,
        fontSize: 10,
        color: themeColor,
      },
    },
    yAxis: { type: 'value', axisLabel: { fontSize: 10, color: themeColor } },
    legend: {
      show: !preparingChart,
      itemWidth: 30,
      textStyle: {
        color: themeColor,
      },
      data: ['Users', 'Active Users', 'New Users'],
    },
    grid: {
      left: '2%',
      right: '2%',
      bottom: '2%',
      containLabel: true,
    },
    series: [
      {
        name: 'Users',
        type: 'line',
        symbol: 'none',
        itemStyle: { color: colors.secondary[500] },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0.3, color: hexToRgba(colors.secondary[500], 0.3) },
              { offset: 1, color: hexToRgba(colors.secondary[500], 0.1) },
            ],
          },
        },
        data: allUsersDataset,
      },
      {
        name: 'Active Users',
        type: 'line',
        symbol: 'none',
        itemStyle: { color: colors.highlight.primary },
        lineStyle: { type: 'dashed' },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0.3, color: hexToRgba(colors.highlight.primary, 0.15) },
              { offset: 1, color: hexToRgba(colors.highlight.primary, 0.05) },
            ],
          },
        },
        data: activeUsersDataset,
      },
      {
        name: 'New Users',
        type: 'bar',
        barWidth: '30%',
        barMaxWidth: 20,
        itemStyle: {
          color: colors.cyan[400],
          opacity: 1,
          borderRadius: [3, 3, 0, 0],
        },
        data: newUsersDataset,
      },
    ],
  };

  // loading Style
  const loadingChartStyle: React.CSSProperties = useMemo(
    () => ({
      fontSize: '14px',
      text: 'Preparing chart...',
      color: colors.highlight.primary,
      textColor: loadingColor,
      maskColor: 'transparent',
      borderRadius: '12px',
      zlevel: 0,
    }),
    [colors, loadingColor]
  );

  return (
    <ReactECharts
      style={chartStyle}
      option={chartOptions}
      notMerge={true}
      lazyUpdate={true}
      loadingOption={loadingChartStyle}
      showLoading={preparingChart as boolean | undefined}
    />
  );
}

export default UsersChart;
