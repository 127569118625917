import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import LoginForm from '../../../components/authforms/LoginForm';
import { selectCurrentAuthData } from 'store/features/auth/authSlice';

export default function LoginView() {
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || '/';
  const { user } = useSelector(selectCurrentAuthData);
  useEffect(() => {
    if (user) navigate('/', { replace: true });
  }, [from, navigate, user]);

  return <LoginForm />;
}
