import React, { useState } from "react";
import {
  Avatar,
  Box,
  Text,
  Button,
  VStack,
  Heading,
  Flex,
  useToast,
  FormControl,
  FormLabel,
  Input,
  Divider,
  useColorModeValue,
} from "@chakra-ui/react";
import { AiOutlineUser } from "react-icons/ai";

import { FaUserPen } from "react-icons/fa6";
import { GoSignOut } from "react-icons/go";
import { TbArrowBackUp } from "react-icons/tb";
import { FaUserCheck } from "react-icons/fa";
import { selectCurrentAuthData, updateUserAttributes } from "store/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { environment } from "environments";
import useLogout from "hooks/useLogout";

interface ProfileDetailsProps {
  given_name?: string;
  family_name?: string;
}

export interface ProfileUserProps {
  email?: string;
  given_name?: string;
  family_name?: string;
  sub?: string;
  id?: string;
}

export default function Profile() {
  const toast = useToast();
  const dispatch = useDispatch()
  const axiosPrivate = useAxiosPrivate()
  const logout = useLogout()

  // Auth
  const { user } = useSelector(selectCurrentAuthData);

  // State
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setSigningOut] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [familyName, setFamilyName] = useState(user?.family_name || "");
  const [givenName, setGivenName] = useState(user?.given_name || "");
  const [showProfileForm, setShowProfileForm] = useState(false);

  const handleUpdateProfile = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMsg("");
    setIsUpdating(true);

    if (familyName === "" && givenName === "") {
      toast({
        description: "No changes to update",
        status: "warning",
      });
      setIsUpdating(false);
      return;
    }

    if (!isNewChange(user, { family_name: familyName, given_name: givenName })) {
      toast({
        description: "No changes to update",
        status: "warning",
      });
      setIsUpdating(false);
      return;
    }

    if (familyName === "" || givenName === "") {
      setErrorMsg("First name and Last name required");
      setIsUpdating(false);
      return;
    }

    const attributes = {
      given_name: givenName,
      family_name: familyName
      // Other attributes with same format as Auth
    }
    try {
      await axiosPrivate.put(
        `${environment.BACKEND_API}/api/profile`,
        { attributes }
      );
      dispatch(updateUserAttributes(attributes))

      setIsUpdating(false);
      setShowProfileForm(false);

      toast({
        position: "bottom-right",
        duration: 1500,
        render: () => (
          <Box
            color="white"
            p={3}
            bg={"highlight.primary"}
            borderRadius={"6px"}
          >
            Profile updated successfully
          </Box>
        ),
      });
    } catch (error) {
      toast({
        description: "Failed to update profile. Try again.",
        status: "warning",
      });
      setErrorMsg("Failed to update profile. Try again.");
      setIsUpdating(false);
    }
  };

  const isNewChange = (
    original: ProfileUserProps | null,
    updates: ProfileDetailsProps
  ) => {
    if (
      original?.given_name !== updates?.given_name ||
      original?.family_name !== updates?.family_name
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleLogout = async () => await logout();

  // useEffect(() => {}, [
  //   errorMsg,
  //   familyName,
  //   givenName,
  //   isLoading,
  //   isUpdating,
  //   showProfileForm,
  // ]);

  // Theme
  const border = useColorModeValue("gray.200", "gray.700");
  const bg = useColorModeValue("gray.50", "dark.800");
  const pageBg = useColorModeValue("transparent", "dark.700");
  const nameColor = useColorModeValue("gray.600", "gray.300");
  const emailColor = useColorModeValue("gray.400", "gray.500");
  const secondaryBtnColor = useColorModeValue("gray.500", "gray.400");
  const secondaryBtnHoverColor = useColorModeValue("gray.700", "gray.500");
  const heading = useColorModeValue("gray.600", "gray.400");

  return (
    <Flex width={'full'} justify={"center"} align={"center"} h="100vh" bg={pageBg}>
      {!showProfileForm ? (
        <Flex
          direction={"column"}
          gap={"30px"}
          py={"30px"}
          px={"40px"}
          bg={bg}
          borderColor={border}
          borderWidth={1}
          borderRadius={"12px"}
        >
          {/* avatar and user details */}
          <Flex align="center" gap={"20px"}>
            {/* avatar */}
            {user?.given_name && user?.family_name ? (
              <>
                <Avatar
                  boxSize={"60px"}
                  bg="primary.400"
                  color={"primary.100"}
                  name={`${user.given_name} ${user.family_name}`}
                />
              </>
            ) : (
              <>
                <Avatar
                  size="xl"
                  bg="primary.400"
                  icon={<AiOutlineUser fontSize="2.5rem" />}
                />
              </>
            )}

            {/* name & email */}
            <Flex direction={"column"} gap={1}>
              {/* name */}
              {(user?.given_name || user?.family_name) && (
                <Text
                  fontSize="20px"
                  fontWeight={500}
                  color={nameColor}
                  textTransform={"capitalize"}
                  lineHeight={1.4}
                >
                  {user?.given_name} {user?.family_name}
                </Text>
              )}

              {/* email */}
              <Text fontSize="14px" color={emailColor} lineHeight={1}>
                {user?.email}
              </Text>
            </Flex>
          </Flex>

          {/* edit and logout buttons */}
          <Flex justify={"flex-end"} align={"center"} w={"full"} gap={2}>
            {/* logout button */}
            <Button
              bg={"transparent"}
              color={secondaryBtnColor}
              borderColor={secondaryBtnColor}
              borderWidth={1}
              borderRadius={"12px"}
              width={"110px"}
              isLoading={isLoading}
              loadingText="Processing.."
              leftIcon={<GoSignOut />}
              onClick={handleLogout}
              _hover={{
                color: secondaryBtnHoverColor,
                borderColor: secondaryBtnHoverColor,
                bg: "transparent",
              }}
            >
              Logout
            </Button>

            {/* edit button */}
            <Button
              color={"neutral.100"}
              bg={"primary.400"}
              borderColor={"primary.400"}
              borderWidth={1}
              borderRadius={"12px"}
              width={"110px"}
              disabled={isLoading}
              loadingText="Processing.."
              leftIcon={<FaUserPen />}
              onClick={() => setShowProfileForm(true)}
              _hover={{ opacity: 0.9 }}
              _focus={{ bg: "teal", borderColor: "teal" }}
            >
              Edit
            </Button>
          </Flex>
        </Flex>
      ) : (
        <Flex
          direction={"column"}
          align={"center"}
          gap={"30px"}
          py={"30px"}
          px={"40px"}
          bg={bg}
          borderColor={border}
          borderWidth={1}
          borderRadius={"12px"}
        >
          <Heading size="lg" color={heading}>
            Update Profile
          </Heading>
          <Divider />
          <VStack spacing={4} w="100%" maxW="md">
            <FormControl w="100%">
              <FormLabel
                htmlFor="givenName"
                color={"gray.500"}
                fontSize={"14px"}
              >
                First name
              </FormLabel>
              <Input
                type="text"
                id="givenName"
                placeholder="Your first name"
                value={givenName}
                onChange={(e) => setGivenName(e.target.value)}
                width="100%"
              />
            </FormControl>
            <FormControl w="100%">
              <FormLabel
                htmlFor="lastname"
                color={"gray.500"}
                fontSize={"14px"}
              >
                Last name
              </FormLabel>
              <Input
                type="text"
                id="familyName"
                placeholder="Your last name"
                value={familyName}
                onChange={(e) => setFamilyName(e.target.value)}
                width="100%"
              />
            </FormControl>
            <Text fontSize="sm" color="jasper">
              {errorMsg}
            </Text>

            <Flex justify={"flex-end"} align={"center"} w={"full"} gap={2}>
              <Button
                bg={"transparent"}
                color={secondaryBtnColor}
                borderColor={secondaryBtnColor}
                borderWidth={1}
                borderRadius={"12px"}
                width={"160px"}
                leftIcon={<TbArrowBackUp />}
                onClick={() => setShowProfileForm(false)}
                _hover={{
                  color: secondaryBtnHoverColor,
                  borderColor: secondaryBtnHoverColor,
                  bg: "transparent",
                }}
              >
                Go back
              </Button>

              <Button
                type="submit"
                color={"neutral.100"}
                bg={"primary.400"}
                borderColor={"primary.400"}
                borderWidth={1}
                borderRadius={"12px"}
                width={"160px"}
                isLoading={isUpdating}
                loadingText="Updating.."
                leftIcon={<FaUserCheck />}
                onClick={handleUpdateProfile}
                _hover={{ opacity: 0.9 }}
                _focus={{ bg: "teal", borderColor: "teal" }}
              >
                Save Changes
              </Button>
            </Flex>
          </VStack>
        </Flex>
      )}
    </Flex>
  );
}
