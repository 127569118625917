import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconType } from 'react-icons';

import {
  Flex,
  Spacer,
  Divider,
  List,
  Box,
  Image,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';

import useNavigationTabs from 'hooks/useNavigationTabs';
import { GetGradients } from 'utils/gradients';
import NavItem from './Item';
import AvatarMenu from './Menu';
import ThemeSwitch from './ThemeSwitch';

export interface ItemProps {
  name: string;
  link: string | undefined;
  icon: IconType;
  tooltip: string;
}

const Nav = () => {
  // Hooks
  const location = useLocation();
  const { navItems } = useNavigationTabs();

  const { pathname } = location;
  const navigate = useNavigate();

  // Theme
  const { colorMode } = useColorMode();
  const { navGradient, navGradientDark } = GetGradients();
  const bgGradient = useColorModeValue(navGradient, navGradientDark);
  const borderColor = useColorModeValue('transparent', 'dark.700');

  const boxShadow = useColorModeValue(
    '0 8px 32px 0 rgba(20, 26, 97, 0.37)',
    'none'
  );
  const bgItem = useColorModeValue('neutral.200', 'dark.700');

  const pathSegments = pathname.split('/');
  const [active, setActive] = useState(pathSegments[1]);

  const isLight = colorMode === 'light';

  useEffect(() => {
    // extract first word after /
    const regex = /\/([^/]+)/;
    // search for a match in the URL using the regex pattern
    const match = pathname.match(regex);
    if (match) {
      const existingLink: ItemProps | undefined = navItems.find(
        (item: ItemProps) => item.name === match[1]
      );
      if (existingLink === undefined) {
        setActive(match[1]);
      } else {
        setActive(existingLink.name);
      }
    }
  }, [pathname, navItems]);

  return (
    <Flex
      direction={'column'}
      h={[
        null,
        null,
        null,
        'calc(100vh - 16px)',
        'calc(100vh - 24px)',
        'calc(100vh - 32px)',
      ]}
      w={[null, null, null, '70px', '90px', '100px']}
      minW={[null, null, null, '70px', '90px', '100px']}
      maxW={[null, null, null, '70px', '90px', '100px']}
      py={[null, null, null, '16px', '20px', '24px']}
      bgGradient={bgGradient}
      boxShadow={boxShadow}
      borderWidth={1}
      borderColor={borderColor}
      borderRadius={'10px'}
      zIndex={100}
    >
      <Flex direction={'column'} align={'center'} justify={'center'}>
        {/* Logo and home */}
        <Box>
          <Image
            h={[null, null, null, '36px', '38px', '40px']}
            src={
              isLight
                ? 'https://reacto.s3.eu-central-1.amazonaws.com/logo/green.svg'
                : 'https://reacto.s3.eu-central-1.amazonaws.com/logo/white.svg'
            }
            opacity={isLight ? 0.8 : 0.4}
            onClick={() => navigate('/')}
            _hover={{ cursor: 'pointer' }}
          />

          <Divider my={4} />
        </Box>

        {/* Main navs */}
        <List spacing={[null, null, null, 2, 3, 3]}>
          {navItems.map((item, index) => (
            <Box
              key={index}
              p={2}
              pb={1}
              bg={active === item.name ? bgItem : 'transparent'}
              borderRadius={'4px'}
            >
              <NavItem item={item} active={active} setActive={setActive} />
            </Box>
          ))}
        </List>
      </Flex>

      <Spacer />

      <Flex
        direction={'column'}
        align="center"
        gap={[null, null, null, 4, 5, 6]}
      >
        <Divider />

        {/* User Avatar */}
        <AvatarMenu />
        <ThemeSwitch />
      </Flex>
    </Flex>
  );
};

export default Nav;
