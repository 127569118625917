import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  useColorModeValue,
  Flex,
  Avatar,
} from '@chakra-ui/react';
import { GetGradients } from 'utils/gradients';
import { Activity } from './History';
import { AiOutlineUser } from 'react-icons/ai';
import { FaCircle } from 'react-icons/fa6';

interface HistoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  histories: Activity[];
}

function HistoryModal({ isOpen, onClose, histories }: HistoryModalProps) {
  // Theme
  const headerColor = useColorModeValue('neutral.100', 'primary.200');
  const contentBg = useColorModeValue('neutral.100', 'dark.800');
  const { privacyHeaderGradient } = GetGradients();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent
        alignSelf={'center'}
        borderRadius={'6px'}
        overflow={'hidden'}
        minW={'800px'}
        maxW={'800px'}
        h={'min(90vh, 650px)'}
        my={'auto'}
        bg={contentBg}
      >
        <ModalHeader
          display={'flex'}
          alignItems={'center'}
          gap={2}
          color={headerColor}
          bgGradient={privacyHeaderGradient}
        >
          <Text fontSize={'16px'} fontWeight={'500'}>
            History
          </Text>
        </ModalHeader>
        <ModalBody my={[null, null, null, 3, 4, 6]} overflowY={'auto'}>
          <Flex direction={'column'}>
            {histories?.map((activity: any, index: number) => (
              <Flex
                key={index}
                align="center"
                gap={2}
                py={4}
                px={2}
                borderTopColor={'gray.300'}
                borderTopWidth={index === 0 ? 0 : 1}
              >
                <Avatar
                  boxSize={[null, null, null, '34px', '38px', '42px']}
                  bg="primary.400"
                  icon={<AiOutlineUser />}
                />
                <Flex
                  direction="column"
                  whiteSpace={'nowrap'}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                >
                  <Text
                    fontSize="sm"
                    whiteSpace={'nowrap'}
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                  >
                    {activity.activity}
                  </Text>
                  <Flex align={'center'} gap={2} color={'gray.500'}>
                    <Text
                      fontSize={[null, null, null, '12px', '13px', '14px']}
                      fontWeight={500}
                      lineHeight={1.3}
                    >
                      By: {activity.user?.given_name}{' '}
                      {activity.user?.family_name}
                    </Text>

                    <Text
                      fontSize={[null, null, null, '2px', '3px', '4px']}
                      lineHeight={1}
                    >
                      <FaCircle />
                    </Text>

                    <Text fontSize={'12px'} opacity={0.8} lineHeight={1}>
                      {activity.time_ago}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default HistoryModal;
