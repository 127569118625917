import { Box, useMediaQuery } from "@chakra-ui/react";
import { Outlet, useLocation } from "react-router-dom";
import MobileView from "views/MobileView";

function AppWrapper() {
    // Hooks
    const location = useLocation();
    const [isMobileView] = useMediaQuery("(max-width: 991px)");

    const hiddenPathsOnSmallScreen = [
        "/login",
        "/reset-password",
        "/",
        "/clients",
    ];

    const isHiddenOnSmallScreen = hiddenPathsOnSmallScreen.some((path) =>
        location?.pathname?.startsWith(path)
    );

    if (isMobileView && isHiddenOnSmallScreen)
        return <MobileView isMobileView={isMobileView} />;

    const isVisible = !isMobileView || !isHiddenOnSmallScreen;

    return (
        <Box visibility={isVisible ? "visible" : "hidden"}>
            <Outlet />
        </Box>
    );
}
export default AppWrapper