import { useState } from 'react';
import { format } from 'date-fns';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa6';
import { AiOutlineUser } from 'react-icons/ai';

import EditUserInfo from 'components/clients/modals/EditUserInfo';
import { User } from 'models/Users';
import { useToggleUserAccessMutation } from 'api/Users/useToggleUserAccessMutation';
import { useResendInvitationMutation } from 'api/Users/useResendInvitationMutation';

interface UserDetailsProps {
  selectedUser: User | null;
  onClose: (status: boolean) => void;
}

export default function UserDetails({
  selectedUser,
  onClose,
}: UserDetailsProps) {

  const toggleUserAccessMutation = useToggleUserAccessMutation();
  const resendInvitationMutation = useResendInvitationMutation();

  const [isProcessing, setIsProcessing] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const onCloseEditModal = () => {
    setIsEditOpen(false);
  };

  const nameColor = useColorModeValue('gray.600', 'gray.300');
  const grayColor = useColorModeValue('gray.400', 'gray.500');

  const onCloseModal = () => {
    if (!isProcessing) {
      onClose(false);
    }
  };

  const toggleUserAccess = async () => {
    if (!selectedUser) return
    setIsProcessing(true);

    toggleUserAccessMutation.mutate(selectedUser, {
      onSettled: () => {
        setIsProcessing(false);
        selectedUser.enabled = !selectedUser.enabled;
      },
    });
  };

  const openEditModal = () => {
    setIsEditOpen(true);
  };

  // Close Edit Modal
  const closeEditModal = (status: boolean) => {
    setIsEditOpen(status);
  };

  const resendInvitation = async () => {
    if (!selectedUser) return
    setIsResending(true);
    resendInvitationMutation.mutate(selectedUser, {
      onSettled: () => {
        setIsResending(false);
        selectedUser.user_status = "FORCE_CHANGE_PASSWORD";
      },
    });
  };

  return (
    <>
      <ModalContent>
        <ModalHeader>User Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {
            <>
              {selectedUser && (
                <Flex alignItems="center" mb={4}>
                  {selectedUser?.given_name && selectedUser?.family_name ? (
                    <>
                      <Avatar
                        size="xl"
                        bg={'secondary.500'}
                        color={'primary.100'}
                        name={`${selectedUser.given_name} ${selectedUser.family_name}`}
                      />
                    </>
                  ) : (
                    <>
                      <Avatar
                        size="xl"
                        bg={'secondary.500'}
                        icon={<AiOutlineUser fontSize="2.5rem" />}
                      />
                    </>
                  )}
                  <Flex flexDirection="column" ml={4}>
                    <Text
                      fontSize="20px"
                      fontWeight={500}
                      color={nameColor}
                      textTransform={'capitalize'}
                      lineHeight={1.4}
                    >
                      {selectedUser?.given_name} {selectedUser?.family_name}
                    </Text>
                    <Text
                      fontSize="14px"
                      mt={2}
                      color={grayColor}
                      lineHeight={1}
                    >
                      {selectedUser.email}
                    </Text>
                    <Flex flexDirection="row" mt={2}>
                      <Text fontSize="14px" color={grayColor} lineHeight={1}>
                        Invited On:
                      </Text>
                      <Text fontSize="14px" lineHeight={1} px={2}>
                        {format(
                          new Date(selectedUser.invited_at),
                          'MMMM d, yyyy'
                        )}
                      </Text>
                      {selectedUser.user_type && (
                        <Text
                          fontSize="5px"
                          mt={1}
                          color={grayColor}
                          lineHeight={1}
                        >
                          <FaCircle />
                        </Text>
                      )}
                      <Text
                        as={'b'}
                        fontSize="14px"
                        lineHeight={1}
                        textTransform={'capitalize'}
                        px={2}
                      >
                        {selectedUser.user_type}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </>
          }

          <Flex flexDirection="row" my={4}>
            {selectedUser?.first_login && (
              <>
                <Text fontSize="14px" color={grayColor} lineHeight={1}>
                  First Login:
                </Text>
                <Text fontSize="14px" lineHeight={1} px={2}>
                  {format(
                    new Date(selectedUser.first_login),
                    "MMM d, yyyy 'at' hh:mma"
                  )}
                </Text>
              </>
            )}

            {selectedUser?.last_login && (
              <>
                <Text fontSize="14px" color={grayColor} lineHeight={1}>
                  Last Login:
                </Text>
                <Text fontSize="14px" lineHeight={1} px={2}>
                  {format(
                    new Date(selectedUser.last_login),
                    "MMM d, yyyy 'at' hh:mma"
                  )}
                </Text>
              </>
            )}
          </Flex>
          <Divider />
          <Box mt={2}>
            <Stack direction="row" spacing={4} align="center">
              <Button
                colorScheme={selectedUser?.enabled ? 'red' : 'primary'}
                variant="ghost"
                size={'sm'}
                isLoading={isProcessing}
                loadingText="Processing"
                onClick={toggleUserAccess}
              >
                {selectedUser?.enabled ? (
                  <Text>Disable Access</Text>
                ) : (
                  <Text>Enable Access</Text>
                )}
              </Button>

              <Button
                colorScheme={'blue'}
                variant="solid"
                size={'sm'}
                onClick={() => selectedUser && openEditModal()}
              >
                Edit
              </Button>

              {selectedUser?.user_status === 'EXPIRED' && (
                <Button
                  colorScheme={'green'}
                  variant="solid"
                  size={'sm'}
                  isLoading={isResending}
                  loadingText="Resending"
                  onClick={resendInvitation}
                >
                  Resend Invitation
                </Button>
              )}
            </Stack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button size={'sm'} colorScheme="blue" mr={3} onClick={onCloseModal}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>

      {/* Update User Info Modal */}
      <Modal
        size={'xs'}
        isCentered
        isOpen={isEditOpen}
        onClose={onCloseEditModal}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <EditUserInfo
          selectedUser={selectedUser}
          onClose={() => closeEditModal(false)}
        />
      </Modal>
    </>
  );
}
