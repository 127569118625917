import { ChangeEvent, useState } from 'react';

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa6';

import { titleCase } from 'components/clients/helpers';
import { User } from 'models/Users';
import { useUpdateUserMutation } from 'api/Users/useUpdateUserMutation';


interface EditUserProps {
  selectedUser: User | null;
  onClose: (status: boolean) => void;
}

interface UserInfoProps {
  given_name: string;
  family_name: string;
  user_type: string;
}

export default function EditUserInfo({ selectedUser, onClose }: EditUserProps) {
  const updateUserMutation = useUpdateUserMutation();

  // Color theme
  const grayColor = useColorModeValue('gray.400', 'gray.500');

  // Edit User Modal
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userType, setUserType] = useState(selectedUser?.user_type || '');
  const [formData, setFormData] = useState({
    firstname: selectedUser?.given_name || '',
    lastname: selectedUser?.family_name || '',
  });

  const userTypes = [
    {
      type: 'free',
      name: 'Free',
      color: 'gray',
    },
    {
      type: 'basic',
      name: 'Basic',
      color: '#477ec6',
    },
    {
      type: 'premium',
      name: 'Premium',
      color: '#efa43a',
    },
    {
      type: 'teams',
      name: 'Teams',
      color: '#c54b8a',
    },
  ];

  const onCloseModal = () => {
    if (!isSubmitting) {
      onClose(false);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onUserTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setUserType(event.target.value);
  };

  const validateForm = () => {
    const payload = {
      first_name: formData.firstname.trim(),
      last_name: formData.lastname.trim(),
      user_type: userType,
    };
    if (
      !isNewChange(selectedUser, {
        given_name: payload.first_name,
        family_name: payload.last_name,
        user_type: payload.user_type,
      })
    ) {
      toast({
        duration: 5000,
        description: 'No changes to update',
        status: 'warning',
        position: 'top-right',
      });
      return false;
    }

    if (payload.first_name === '' && payload.last_name === '') {
      toast({
        duration: 5000,
        description: 'Both firstname and lastname cannot be empty',
        status: 'warning',
        position: 'top-right',
      });
      return false;
    }
    return true;
  };

  const isNewChange = (original: User | null, updates: UserInfoProps) => {
    if (
      original?.given_name !== updates?.given_name ||
      original?.family_name !== updates?.family_name ||
      (original?.user_type !== null &&
        original?.user_type !== updates?.user_type)
    ) {
      return true;
    } else {
      return false;
    }
  };

  // form submit - update info
  const updateUserInfo = async () => {
    setIsSubmitting(true);
    if (!validateForm()) {
      setIsSubmitting(false);
      return;
    }

    // Good to proceed: Update User Info
    const payload = {
      first_name: titleCase(formData.firstname.trim()),
      last_name: titleCase(formData.lastname.trim()),
      user_type: userType,
    };

    updateUserMutation.mutate({ payload, selectedUser }, {
      onSuccess: () => {
        setIsSubmitting(false);
        if (selectedUser) {
          selectedUser.user_type = payload?.user_type;
          selectedUser.given_name = payload?.first_name;
          selectedUser.family_name = payload?.last_name;
        }
        onClose(false);
      },
      onError: () => {
        setIsSubmitting(false);
      }
    });

  };

  return (
    <ModalContent>
      <ModalHeader>Update User Details</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl id="firstname">
          <FormLabel fontSize={'14px'} color={'gray.600'} lineHeight={'1.3'}>
            First name
          </FormLabel>
          <Input
            type="text"
            name="firstname"
            borderRadius={'xl'}
            placeholder="First name"
            value={formData.firstname}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl mt={4} id="lastname">
          <FormLabel fontSize={'14px'} color={'gray.600'} lineHeight={'1.3'}>
            Last name
          </FormLabel>
          <Input
            type="text"
            name="lastname"
            borderRadius={'xl'}
            placeholder="Last name"
            value={formData.lastname}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl mt={4} id="usertype">
          <FormLabel fontSize={'14px'} color={'gray.600'} lineHeight={'1.3'}>
            User Type
          </FormLabel>

          <InputGroup>
            <Flex
              alignItems="center"
              borderWidth="1px"
              borderRadius={'xl'}
              bg="transparent"
              width={'100%'}
            >
              <InputLeftAddon
                pointerEvents="none"
                borderWidth="0"
                color={
                  userType && userType !== ''
                    ? userTypes.find((elm: any) => elm.type === userType)?.color
                    : 'gray'
                }
              >
                <FaCircle />
              </InputLeftAddon>
              <Select
                value={userType}
                color={
                  userType && userType !== ''
                    ? userTypes.find((elm: any) => elm.type === userType)?.color
                    : 'gray'
                }
                onChange={onUserTypeChange}
                placeholder="Change User Type"
                borderWidth="0"
                disabled={
                  selectedUser?.user_type === null ||
                    selectedUser?.enabled === false
                    ? true
                    : false
                }
              >
                {userTypes.map((type, index) => (
                  <option key={index} value={type.type}>
                    {type.name}
                  </option>
                ))}
              </Select>
            </Flex>
          </InputGroup>
        </FormControl>
        {(selectedUser?.user_type === null ||
          selectedUser?.enabled === false) && (
            <Text fontSize="sm" mt={2} color={grayColor}>
              Cannot update <b>USER TYPE</b> for disabled users or users who have
              not logged in yet
            </Text>
          )}
      </ModalBody>
      <ModalFooter>
        <Button
          mr={3}
          size={'sm'}
          variant="ghost"
          colorScheme="red"
          onClick={onCloseModal}
        >
          Cancel
        </Button>
        <Button
          size={'sm'}
          type="submit"
          colorScheme="blue"
          isLoading={isSubmitting}
          loadingText="Updating..."
          onClick={updateUserInfo}
        >
          Update
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}
