import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  useColorModeValue,
  useTheme,
  Link,
  Text,
  Avatar,
} from "@chakra-ui/react";

import { ProfileUserProps } from "components/authforms/Profile";

// icons
import { RiUserSettingsLine } from "react-icons/ri";
import { GoSignOut } from "react-icons/go";

import { useSelector } from "react-redux";
import { selectCurrentAuthData } from "store/features/auth/authSlice";

import { GetGradients } from "utils/gradients";
import { hexToRgba } from "utils/helpers";
import useLogout from "hooks/useLogout";

interface UserProps {
  user: ProfileUserProps;
}

export default function AvatarMenu() {
  // Auth
  const { user } = useSelector(selectCurrentAuthData);

  // Theme
  const { colors } = useTheme();

  const { lGradient2 } = GetGradients();
  const borderColor = useColorModeValue(
    hexToRgba(colors.blue[600], 0.2),
    "dark.800"
  );
  const bgGradient = useColorModeValue(
    lGradient2,
    `linear(to-b, ${colors.dark[800]}, ${colors.dark[800]})`
  );

  return (
    <>
      <Menu>
        <MenuButton aria-label="Options">
          <Avatar
            name={user ? user.given_name || user.email : ""}
            bg={"secondary.200"}
            zIndex={10}
            size={"sm"}
          />
        </MenuButton>
        <MenuList
          bgGradient={bgGradient}
          borderColor={borderColor}
          zIndex={999}
        >
          <UserDetails user={user ?? {}} />
          <AccountSettings />

          <MenuDivider />
          <Logout />
        </MenuList>
      </Menu>
    </>
  );
}

function AccountSettings() {
  // Theme
  const { colors } = useTheme();
  const color = useColorModeValue("gray.600", "gray.400");
  const bg = useColorModeValue(hexToRgba(colors.blue[500], 0.12), "dark.800");

  return (
    <MenuItem
      display={"flex"}
      gap={1}
      alignItems={"center"}
      bg={"transparent"}
      color={color}
      w={"100%"}
      h={"100%"}
      p={0}
      _hover={{ bg: bg }}
    >
      <Link
        h={"100%"}
        w={"100%"}
        as={RouterLink}
        to={"/profile"}
        display={"flex"}
        alignItems={"center"}
        gap={1}
        py={1.5}
        px={3}
        _hover={{ textDecoration: "none" }}
      >
        <Icon as={RiUserSettingsLine} boxSize="16px" />
        <Text fontSize={"14px"} fontWeight={"500"}>
          Account Settings
        </Text>
      </Link>
    </MenuItem>
  );
}

function Logout() {
  // Hooks
  const logout = useLogout()

  // Theme
  const { colors } = useTheme();
  const color = useColorModeValue("gray.600", "gray.400");
  const bg = useColorModeValue(hexToRgba(colors.blue[500], 0.12), "dark.800");

  // Handler
  const handleLogout = async () => await logout();

  return (
    <MenuItem
      display={"flex"}
      gap={1}
      alignItems={"center"}
      bg={"transparent"}
      color={color}
      onClick={handleLogout}
      _hover={{ bg: bg }}
    >
      <Icon as={GoSignOut} boxSize="16px" />
      <Text fontSize={"14px"} fontWeight={"500"}>
        Log out
      </Text>
    </MenuItem>
  );
}

function UserDetails({ user }: UserProps) {
  const { given_name, family_name, email } = user;

  // Theme
  const color = useColorModeValue("blue.700", "blue.500");

  return (
    <Flex direction={"column"} gap={1} mt={1} mb={4} px={3}>
      {family_name && given_name ? (
        <Text
          textTransform={"capitalize"}
          fontWeight={"bold"}
          fontSize={"14px"}
          color={color}
        >
          {`${given_name} ${family_name}`}
        </Text>
      ) : null}
      {email ? (
        <Text color={"blue.600"} fontSize={"12px"}>
          {email}
        </Text>
      ) : null}
    </Flex>
  );
}
