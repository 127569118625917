import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import { ROLES } from 'utils/roles';

import AppWrapper from 'components/ui/AppWrapper';
import Layout from 'components/ui/Layout';

import LoginView from 'views/auth/login';
import Home from 'views/home';
import ClientsView from 'views/clients';
import ProfileView from 'views/profile';
import PageNotFound from 'views/PageNotFound';
import RequireAuth from 'components/authforms/RequireAuth';
import Unauthorized from 'views/Unauthorized';

import PersistLogin from 'components/authforms/PersistLogin';
import AuthLayout from 'views/auth/AuthLayout';
import ResetPasswordView from 'views/auth/resetPassword';

export default function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route element={<AppWrapper />}>
          {/* Public routes */}
          <Route element={<PersistLogin />}>
            <Route path="unauthorized" element={<Unauthorized />} />

            {/* Auth */}
            <Route element={<AuthLayout />}>
              <Route path="login" element={<LoginView />} />
              <Route path="reset-password" element={<ResetPasswordView />} />
            </Route>

            {/* Protected routes */}
            <Route
              element={
                <RequireAuth allowedRoles={[...Object.values(ROLES)]} />
              }
            >
              <Route element={<Layout />}>
                {/* other routes */}
                <Route index element={<Home />} />
                <Route path="dashboard" element={<Home />} />
                <Route path="clients" element={<ClientsView />} />
                <Route path="profile" element={<ProfileView />} />
              </Route>
            </Route>
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </Router>
  );
}
