import {
  Flex,
  Icon,
  Text,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react';
import { FaUserPlus } from 'react-icons/fa6';

interface NewUserProps {
  onAddNewUser: () => void;
}

function NewUser({ onAddNewUser }: NewUserProps) {
  // Theme
  const { colors } = useTheme();
  const color = useColorModeValue(colors.neutral[200], colors.dark[700]);

  return (
    <Flex
      align={'center'}
      justify={'center'}
      gap={2}
      fontSize={[null, null, null, '14px', '14px', '16px']}
      py={3}
      w={'fit-content'}
      minW={'140px'}
      h={'fit-content'}
      lineHeight={1}
      transition={'.3s all ease'}
      _hover={{
        opacity: 0.85,
        transition: '.3s all ease',
      }}
      cursor={'pointer'}
      borderWidth={1}
      borderColor={'highlight.primary'}
      borderRadius={20}
      color={color}
      bgGradient={`linear(90deg, ${colors.highlight.primary} 20%, ${colors.secondary[500]})`}
      onClick={onAddNewUser}
    >
      <Icon
        as={FaUserPlus}
        fontSize={[null, null, null, '14px', '14px', '16px']}
      />
      <Text fontWeight={'600'}>New User</Text>
    </Flex>
  );
}

export default NewUser;
