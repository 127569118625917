import { extendTheme } from "@chakra-ui/react";
import { colors as caribeanColors } from "./caribean";

const theme = extendTheme({
  colors: caribeanColors,
  fonts: { body: "SF UI Text, system-ui, sans-serif" },
  textStyles: {},
  components: {
    Heading: {},
    Text: {},
    Headlight: {},
    Button: { defaultProps: { colorScheme: "primary" } },
    Input: {
      defaultProps: {
        colorScheme: "primary",
        focusBorderColor: "primary.200",
      },
    },
    InputGroup: {},
    Card: {},
    Checkbox: { defaultProps: { colorScheme: "neutral" } },
    Tabs: { defaultProps: { colorScheme: "primary" } },
    Radio: { defaultProps: { colorScheme: "neutral" } },
    Switch: { defaultProps: { colorScheme: "neutral" } },
  },
});

export default theme;

export type Theme = typeof theme;