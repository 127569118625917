import { Flex, useColorModeValue, useTheme } from "@chakra-ui/react";
import Nav from "layouts/nav";
import { Outlet } from "react-router-dom";
import { GetGradients } from "utils/gradients";

function Layout() {
    // Theme
    const { colors } = useTheme();
    const { lGradient2 } = GetGradients();

    const bgGradient = useColorModeValue(
        lGradient2,
        `linear(to-b, ${colors.dark[800]}, ${colors.dark[800]})`
    );

    return (
        <Flex
            position={'relative'}
            overflow={'hidden'}
            bgGradient={bgGradient}
            p={[null, null, null, 2, 3, 4]}
        >
            {/* navbar */}
            <Nav />

            {/* main panel */}
            <Outlet />
        </Flex>
    );
}

export default Layout