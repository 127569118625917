import { Flex, Icon, Text } from "@chakra-ui/react";
import { PiSealWarning } from "react-icons/pi";

interface MainPanelErrorProps {
  errorMessage?: string;
}

export default function MainPanelError({
  errorMessage = "Something went wrong. Please try again later.",
}: MainPanelErrorProps) {
  return (
    <Flex
      h={"100%"}
      w={"100%"}
      overflow={"hidden"}
      align={"center"}
      justify={"center"}
      textAlign={"center"}
      color={"orange.400"}
      p={3}
      wrap={"wrap"}
      gap={3}
      direction={"column"}
    >
      <Icon as={PiSealWarning} boxSize={"40px"} />
      <Text fontWeight={"500"} fontSize={"14px"}>
        {errorMessage}
      </Text>
    </Flex>
  );
}
