import { useMemo } from 'react';
import {
  Box,
  Flex,
  Heading,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';

import UsersTable from 'components/clients/UsersTable';
import { mWUsers, maxHeight } from 'utils/responsive';
import Loading from 'components/ui/Loading';
import { errorHandler } from 'utils/helpers';
import { useUsersAPI } from 'api/Users/useUsersAPI';
import { useQuery } from '@tanstack/react-query';
import MainPanelError from 'components/ui/MainPanelError';
import { User } from 'models/Users';

export default function ClientsView() {
  // Theme
  const headingColor = useColorModeValue('secondary.800', 'neutral.400');

  const { getUsers } = useUsersAPI();

  const { isPending, error, data } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
    staleTime: 2 * 1000 * 60,
  });

  const usersList: User[] = useMemo(() => {
    let sorted = data?.sort(
      (a: User, b: User) =>
        new Date(b.invited_at).getTime() - new Date(a.invited_at).getTime()
    );
    sorted = sorted?.filter(
      (user: User) => user.role !== 'admin' && user.role !== 'manager'
    );

    return sorted;
  }, [data]);

  const UsersHeight = useBreakpointValue(maxHeight);

  let initiallySortedUsersList = usersList;
  // const hasNames = usersList?.filter((u: User) => u?.given_name);
  // const hasNoNames = usersList?.filter((u: User) => !u?.given_name);

  // let hasNamesSorted = [...hasNames].sort(byName);

  // initiallySortedUsersList = [...hasNamesSorted, ...hasNoNames];
  // initiallySortedUsersList = [...usersList].sort(byDate);

  return (
    <Flex w={mWUsers} mx={'auto'} direction={'column'} pt={8} pb={2} gap={6}>
      {/* heading */}
      <Heading
        fontSize={[null, null, null, '20px', '24px', '28px']}
        color={headingColor}
        fontWeight={'regular'}
        lineHeight={1}
      >
        Users
      </Heading>

      {error ? (
        <MainPanelError
          errorMessage={errorHandler(error).message}
        />
      ) : isPending ? (
        <Box w={'100%'} h={`calc(${UsersHeight} - 100px)`}>
          <Loading message="Fetching users..." />
        </Box>
      ) : (
        <UsersTable usersList={initiallySortedUsersList} />
      )}
    </Flex>
  );
}
