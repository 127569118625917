import { Tag, Td } from '@chakra-ui/react';

interface UserStatusProps {
  status: string;
  enabled: boolean;
}

export default function UserStatus({ status, enabled }: UserStatusProps) {
  const isActive = (status === 'CONFIRMED' || status === 'EXTERNAL_PROVIDER') && enabled;

  return (
    <Td border={'none'} minH={'72px'}>
      <Tag
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        size={'sm'}
        minW={'68px'}
        variant="solid"
        colorScheme={isActive ? 'green' : 'gray'}
      >
        {isActive ? 'Active' : 'Inactive'}
      </Tag>
    </Td>
  );
}
