import { ChangeEvent, useRef, useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/react';
import { isValidEmail, titleCase } from 'components/clients/helpers';
import { useInviteNewUserMutation } from 'api/Users/useInviteNewUserMutation';

interface ModalProps {
  onClose: (status: boolean) => void;
}

export default function InviteNewUser({ onClose }: ModalProps) {
  const inviteNewUserMutation = useInviteNewUserMutation();

  // Invite User Modal
  const initialRef = useRef<HTMLInputElement>(null);
  const lastnameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
  });

  const closeModal = () => {
    if (!isSubmitting) {
      onClose(false);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setErrorMsg('');
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [errors, setErrors] = useState({
    firstname: '',
    lastname: '',
    email: '',
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Validation for First name
    if (!formData.firstname.trim()) {
      newErrors.firstname = 'First name is required';
      setErrorMsg(newErrors.firstname);
      setErrors(newErrors);
      isValid = false;
      return isValid;
    } else {
      newErrors.firstname = '';
      setErrorMsg('');
      setErrors(newErrors);
    }

    // Validation for Last name
    if (!formData.lastname.trim()) {
      newErrors.lastname = 'Last name is required';
      setErrorMsg(newErrors.lastname);
      setErrors(newErrors);
      isValid = false;
      return isValid;
    } else {
      newErrors.lastname = '';
      setErrorMsg('');
      setErrors(newErrors);
    }

    // Validation for Email
    if (!formData.email.trim() || !isValidEmail(formData.email)) {
      newErrors.email = 'Invalid email address';
      setErrorMsg(newErrors.email);
      setErrors(newErrors);
      isValid = false;
      return isValid;
    } else {
      newErrors.email = '';
      setErrorMsg('');
      setErrors(newErrors);
    }

    setErrors(newErrors);
    return isValid;
  };

  // form submit
  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (validateForm()) {
      const USER = {
        first_name: titleCase(formData.firstname.trim()),
        last_name: titleCase(formData.lastname.trim()),
        email: formData.email,
      };

      inviteNewUserMutation.mutate(USER, {
        onSettled: () => {
          setIsSubmitting(false);
          formData.firstname = formData.lastname = formData.email = '';
          onClose(false);
        },
      });
    } else {
      setIsSubmitting(false);
      // Form is invalid, focus on the first invalid input field
      if (
        initialRef.current !== null &&
        (!formData.firstname.trim() || errors.firstname.length > 0)
      ) {
        initialRef.current.focus();
      } else if (
        lastnameRef.current !== null &&
        (!formData.lastname.trim() || errors.lastname.length > 0)
      ) {
        lastnameRef.current.focus();
      } else if (
        emailRef.current !== null &&
        (!formData.email.trim() || errors.email.length > 0)
      ) {
        emailRef.current.focus();
      }
    }
  };

  return (
    <ModalContent>
      <ModalHeader>Invite a New User</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl id="firstname">
          <FormLabel fontSize={'14px'} color={'gray.600'} lineHeight={'1.3'}>
            First name
          </FormLabel>
          <Input
            type="text"
            name="firstname"
            ref={initialRef}
            placeholder="First name"
            value={formData.firstname}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl mt={4} id="lastname">
          <FormLabel fontSize={'14px'} color={'gray.600'} lineHeight={'1.3'}>
            Last name
          </FormLabel>
          <Input
            type="text"
            name="lastname"
            ref={lastnameRef}
            placeholder="Last name"
            value={formData.lastname}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl mt={4} id="email">
          <FormLabel fontSize={'14px'} color={'gray.600'} lineHeight={'1.3'}>
            Email
          </FormLabel>
          <Input
            type="email"
            name="email"
            ref={emailRef}
            placeholder="Email address"
            value={formData.email}
            onChange={handleInputChange}
          />
        </FormControl>
        <Text fontSize="sm" color="jasper">
          {errorMsg}
        </Text>
      </ModalBody>
      <ModalFooter>
        <Button
          mr={3}
          size={'sm'}
          variant="ghost"
          colorScheme="red"
          onClick={closeModal}
        >
          Close
        </Button>
        <Button
          size={'sm'}
          type="submit"
          colorScheme="blue"
          isLoading={isSubmitting}
          loadingText="Inviting..."
          onClick={handleSubmit}
        >
          Invite
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}
