import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Heading,
  SkeletonCircle,
  SkeletonText,
  Stack,
  StackDivider,
  Text,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { FaCircle } from 'react-icons/fa6';
import { TbHistoryToggle } from 'react-icons/tb';

import moment from 'moment';

import HistoryModal from './HistoryModal';
import { User } from 'models/Users';
import { useUsersAPI } from 'api/Users/useUsersAPI';
import { useQuery } from '@tanstack/react-query';
import MainPanelError from 'components/ui/MainPanelError';
import { errorHandler } from 'utils/helpers';

export interface Activity {
  activity: string;
  created_at: string;
  user: User;
  time_ago?: string;
}

export default function History() {

  const { getAllActivities } = useUsersAPI();

  // State
  const [show, setShow] = useState(false);

  // Theme
  const cardBg = useColorModeValue('neutral.100', 'dark.700');
  const iconColor = useColorModeValue('gray.600', 'neutral.600');
  const headerColor = useColorModeValue('gray.700', 'neutral.400');

  const { isPending, error, data } = useQuery({
    queryKey: ["histories"],
    queryFn: getAllActivities,
    staleTime: 2 * 1000 * 60,
  });


  const histories = useMemo(() => {
    let sortedData = data?.sort(
      (a: any, b: any) =>
        new Date(b.created_at).getTime() -
        new Date(a.created_at).getTime()
    );

    sortedData?.forEach((element: any) => {
      element['time_ago'] = moment(element.created_at).fromNow();
    });

    return sortedData;
  }, [data]);

  return (
    <>
      <Card bg={cardBg} w={'100%'}>
        <CardBody w={'100%'}>
          <Stack divider={<StackDivider />} spacing="4">
            {/* Header */}
            <Flex align={'center'} gap={2}>
              <Icon
                as={TbHistoryToggle}
                boxSize={[null, null, null, '16px', '18px', '20px']}
                color={iconColor}
              />
              <Heading
                fontSize={[null, null, null, '16px', '18px', '20px']}
                fontWeight={'500'}
                lineHeight={1}
                color={headerColor}
              >
                History
              </Heading>
            </Flex>

            {/* List */}
            {error ? (
              <MainPanelError
                errorMessage={errorHandler(error).message}
              />
            ) : isPending ? (
              <>
                {[1, 2, 3, 4].map((index: number) => (
                  <Flex key={index} align="center" gap={2} my={4}>
                    <SkeletonCircle
                      size={[null, null, null, '34px', '38px', '42px']}
                    />
                    <Flex direction="column">
                      <SkeletonText
                        color={'grey'}
                        noOfLines={2}
                        spacing="3"
                        skeletonHeight="8px"
                        width={250}
                      />
                    </Flex>
                  </Flex>
                ))}
              </>
            ) : (
              histories?.slice(0, 4)?.map((activity: any, index: number) => (
                <Flex
                  key={index}
                  align="center"
                  gap={2}
                  my={[null, null, null, 3, 3, 4]}
                >
                  <Avatar
                    boxSize={[null, null, null, '34px', '38px', '42px']}
                    bg="primary.400"
                    icon={<AiOutlineUser />}
                  />
                  <Flex
                    direction="column"
                    whiteSpace={'nowrap'}
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                  >
                    <Text
                      fontSize="sm"
                      whiteSpace={'nowrap'}
                      overflow={'hidden'}
                      textOverflow={'ellipsis'}
                    >
                      {activity.activity}
                    </Text>
                    <Flex align={'center'} gap={[null, null, null, 3, 3, 4]}>
                      <Text
                        fontSize={[null, null, null, '12px', '13px', '14px']}
                        fontWeight={500}
                        color={'gray.500'}
                      >
                        By: {activity.user?.given_name}{' '}
                        {activity.user?.family_name}
                      </Text>
                      <Text
                        fontSize={[null, null, null, '2px', '3px', '4px']}
                        lineHeight={1}
                      >
                        <FaCircle />
                      </Text>
                      <Text
                        fontSize={[null, null, null, '12px', '13px', '14px']}
                        color={'gray.500'}
                      >
                        {activity.time_ago}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              ))
            )}
          </Stack>
        </CardBody>
        <CardFooter>
          <Button
            fontSize={[null, null, null, '12px', '14px', '16px']}
            colorScheme="blue"
            borderRadius={'20px'}
            px={6}
            w={'fit-content'}
            isDisabled={isPending}
            onClick={() => setShow(true)}
          >
            View all
          </Button>
        </CardFooter>
      </Card>

      <HistoryModal
        histories={histories}
        isOpen={show}
        onClose={() => setShow(false)}
      />
    </>
  );
}
