import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useUsersAPI } from './useUsersAPI';
import { useToast, Box } from '@chakra-ui/react';
import { selectCurrentAuthData } from 'store/features/auth/authSlice';
import { useSelector } from 'react-redux';
import { errorHandler } from 'utils/helpers';
import { User } from 'models/Users';

export const useToggleUserAccessMutation = () => {
    const { disableUser, enableUser, saveAdminActivity } = useUsersAPI();
    const { user } = useSelector(selectCurrentAuthData);
    const toast = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (USER: User) => {
            // Use cognito sub instead of email
            const payload = { email: USER.cognito_sub };
            if (USER.enabled) {
                await disableUser(payload);
                return { ...USER, enabled: false };
            } else {
                await enableUser(payload);
                return { ...USER, enabled: true };
            }
        },
        onSuccess: async (updatedUser) => {
            const activity = {
                user_id: user.id,
                activity: updatedUser.enabled ? `Enabled: ${updatedUser.email}` : `Disabled: ${updatedUser.email}`,
            };

            await saveAdminActivity(activity);

            toast({
                position: 'top-right',
                duration: 3000,
                render: () => (
                    <Box
                        color="white"
                        p={3}
                        bg={'highlight.primary'}
                        borderRadius={'6px'}
                    >
                        {updatedUser.enabled ? 'User access enabled successfully' : 'User access disabled successfully'}
                    </Box>
                ),
            });

            // Invalidate and refetch
            queryClient.refetchQueries({ queryKey: ['users'] })
            queryClient.refetchQueries({ queryKey: ['histories'] })
        },
        onError: (error) => {
            toast({
                description: errorHandler(error).message,
                status: 'error',
                position: 'top-right',
            });
        },
    });
};