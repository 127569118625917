import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

// store
import { store } from './store/store';
import { Provider } from 'react-redux';

// theme
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import AppRoutes from './routes';

import * as serviceWorker from './serviceWorker';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

if (process.env.NODE_ENV === 'production') disableReactDevTools();

// reactQuery Client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <AppRoutes />
      </ChakraProvider>
      {/* React Query DevTools */}
      <ReactQueryDevtools
        initialIsOpen={false}
        buttonPosition={"bottom-right"}
      />
    </QueryClientProvider>
  </Provider>
);

serviceWorker.unregister();

reportWebVitals();
