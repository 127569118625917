import { useColorMode, Switch } from "@chakra-ui/react";
import { useState } from "react";

const ThemeSwitch = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [isChecked, setIsChecked] = useState(colorMode === "dark");

  const handleToggleChange = () => {
    setIsChecked(!isChecked);
    toggleColorMode();
  };

  return (
    <Switch
      colorScheme={"primary"}
      isChecked={isChecked}
      onChange={handleToggleChange}
    />
  );
};

export default ThemeSwitch;
