import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useUsersAPI } from './useUsersAPI';
import { useToast, Box } from '@chakra-ui/react';
import { errorHandler } from 'utils/helpers';
import { useSelector } from 'react-redux';
import { selectCurrentAuthData } from 'store/features/auth/authSlice';

interface User {
    email: string;
    first_name: string;
    last_name: string;
}

export const useInviteNewUserMutation = () => {
    const { inviteUser, saveAdminActivity } = useUsersAPI();
    const { user } = useSelector(selectCurrentAuthData);
    const toast = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (USER: User) => {
            await inviteUser(USER);
            return USER;
        },
        onSuccess: async (USER) => {
            const activity = {
                user_id: user.id,
                activity: `Invited a new user: ${USER.email}`,
            };

            await saveAdminActivity(activity);

            toast({
                position: 'top-right',
                duration: 3000,
                render: () => (
                    <Box
                        color="white"
                        p={3}
                        bg={'highlight.primary'}
                        borderRadius={'6px'}
                    >
                        User invited successfully
                    </Box>
                ),
            });

            queryClient.refetchQueries({ queryKey: ['users'] })
            queryClient.refetchQueries({ queryKey: ['histories'] })
        },
        onError: (error: any) => {
            toast({
                description: errorHandler(error).message,
                status: 'error',
                position: 'top-right',
            });
        },
    });
};