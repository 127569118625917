import {
  Box,
  Card,
  CardBody,
  Flex,
  SimpleGrid,
  Spinner,
  Tag,
  Text,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiFillCreditCard } from 'react-icons/ai';
import {
  BsFillPersonCheckFill,
  BsFillPersonPlusFill,
  BsPeopleFill,
} from 'react-icons/bs';
import { User } from 'models/Users';

interface OverviewProps {
  users: User[];
  loading: boolean;
}

export default function Overview({ users, loading }: OverviewProps) {
  // Theme
  const { colors } = useTheme();
  const cardBg = useColorModeValue('neutral.100', 'dark.700');
  const headerColor = useColorModeValue('gray.500', 'gray.400');
  const iconColor = useColorModeValue('white', colors.dark[700]);

  // Hooks
  const navigate = useNavigate();

  // Handler
  function showUsers(filter: string) {
    navigate(`/clients?filter=${filter}`);
  }

  const activeUsers = useMemo(
    () =>
      users?.filter((user: User) => {
        if (!user?.last_login) return false;
        else {
          const lastLogin = new Date(user?.last_login);
          const currentDate: Date = new Date();

          // Calculate the difference in milliseconds
          const differenceInMs: number =
            currentDate.getTime() - lastLogin.getTime();

          // Convert milliseconds to weeks
          const msInWeek: number = 7 * 24 * 60 * 60 * 1000;
          const differenceInWeeks: number = differenceInMs / msInWeek;

          const isWithinOneWeek = differenceInWeeks < 1;

          return (
            user.user_status === 'CONFIRMED' && user.enabled && isWithinOneWeek
          );
        }
      }),
    [users]
  );

  const freeUsers = useMemo(
    () => users?.filter((user: User) => user.user_type === 'free'),
    [users]
  );

  const teamsUsers = useMemo(
    () => users?.filter((user: User) => user.user_type === 'teams'),
    [users]
  );

  const basicUsers = useMemo(
    () => users?.filter((user: User) => user.user_type === 'basic'),
    [users]
  );

  const premiumUsers = useMemo(
    () => users?.filter((user: User) => user.user_type === 'premium'),
    [users]
  );

  // Get new users (1 month) from users who were invited and logged in
  const newUsers = useMemo(() => {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    return users?.filter(
      (user: User) =>
        user?.first_login && new Date(user.first_login) >= oneMonthAgo
    );
  }, [users]);

  // Calculate the total count for the previous period
  const previousPeriodUsers = useMemo(() => {
    const previousPeriodStart = new Date();
    previousPeriodStart.setMonth(previousPeriodStart.getMonth() - 2); // Two months ago
    const previousPeriodEnd = new Date();
    previousPeriodEnd.setMonth(previousPeriodEnd.getMonth() - 1); // One month ago

    const users = activeUsers?.filter((user: User) => {
      const invited_at = new Date(user.invited_at);
      return (
        invited_at >= previousPeriodStart && invited_at < previousPeriodEnd
      );
    });
    return users;
  }, [activeUsers]);

  const percentageChange = useMemo(() => {
    const change =
      previousPeriodUsers?.length !== 0
        ? ((newUsers?.length - previousPeriodUsers?.length) /
          previousPeriodUsers?.length) *
        100
        : 0;
    return Number(change.toFixed(1));
  }, [newUsers, previousPeriodUsers]);

  return (
    <SimpleGrid columns={4} spacingX="10px" w={'100%'}>
      {/* Total Users */}
      <Card bg={cardBg}>
        <CardBody px={3} py={[null, null, null, 3, 4, 5]}>
          <Flex justify="space-between" align="center">
            <Flex direction={'column'} gap={1.5} w={'100%'}>
              {/* header */}
              <Text
                fontSize={[null, null, null, '12px', '13px', '14px']}
                fontWeight={'500'}
                lineHeight={1}
                color={headerColor}
              >
                Total Users
              </Text>

              {loading ? (
                <Spinner size="xs" />
              ) : (
                <Text
                  fontSize={[null, null, null, '14px', '16px', '18px']}
                  fontWeight={700}
                  lineHeight={1}
                  cursor={'pointer'}
                  w={'fit-content'}
                  onClick={() => showUsers('all')}
                >
                  {users?.length}
                </Text>
              )}
            </Flex>

            {/* icon */}
            <Box
              display={[null, null, null, 'none', 'initial', 'initial']}
              bg={colors.highlight['primary']}
              p={2}
              borderRadius="md"
            >
              <BsPeopleFill size={'16px'} color={iconColor} />
            </Box>
          </Flex>
        </CardBody>
      </Card>

      {/* New Users */}
      <Card bg={cardBg}>
        <CardBody px={3} py={[null, null, null, 3, 4, 5]}>
          <Flex justify="space-between" align="center">
            <Flex direction={'column'} gap={1.5} w={'100%'}>
              {/* header */}
              <Text
                fontSize={[null, null, null, '12px', '13px', '14px']}
                fontWeight={'500'}
                color={headerColor}
                lineHeight={1}
              >
                New Users
              </Text>
              {loading ? (
                <Spinner size="xs" />
              ) : (
                <Flex align={'center'} gap={2}>
                  <Text
                    fontSize={[null, null, null, '14px', '16px', '18px']}
                    fontWeight={700}
                    lineHeight={1}
                    cursor={'pointer'}
                    w={'fit-content'}
                    onClick={() => showUsers('new')}
                  >
                    {newUsers?.length}
                  </Text>
                  <Text
                    color={percentageChange >= 0 ? 'green' : 'red'}
                    fontSize={[null, null, null, '14px', '16px', '18px']}
                    fontWeight={700}
                    lineHeight={1}
                  >
                    {percentageChange}%
                  </Text>
                </Flex>
              )}
            </Flex>

            {/* icon */}
            <Box
              display={[null, null, null, 'none', 'initial', 'initial']}
              bg={colors.highlight['primary']}
              p={2}
              borderRadius="md"
            >
              <BsFillPersonPlusFill size={'16px'} color={iconColor} />
            </Box>
          </Flex>
        </CardBody>
      </Card>

      {/* Active Users */}
      <Card bg={cardBg}>
        <CardBody px={3} py={[null, null, null, 3, 4, 5]}>
          <Flex justify="space-between" align="center">
            <Flex direction={'column'} gap={1.5} w={'100%'}>
              {/* header */}
              <Text
                fontSize={[null, null, null, '12px', '13px', '14px']}
                fontWeight={'500'}
                color={headerColor}
                lineHeight={1}
              >
                Active Users
              </Text>

              {loading ? (
                <Spinner size="xs" />
              ) : (
                <Text
                  fontSize={[null, null, null, '14px', '16px', '18px']}
                  fontWeight={700}
                  cursor={'pointer'}
                  onClick={() => showUsers('active')}
                  lineHeight={1}
                  w={'fit-content'}
                >
                  {activeUsers?.length}
                </Text>
              )}
            </Flex>

            {/* icon */}
            <Box
              display={[null, null, null, 'none', 'initial', 'initial']}
              bg={colors.highlight['primary']}
              p={2}
              borderRadius="md"
            >
              <BsFillPersonCheckFill size={'16px'} color={iconColor} />
            </Box>
          </Flex>
        </CardBody>
      </Card>

      <Card bg={cardBg}>
        <CardBody px={3} py={[null, null, null, 3, 4, 5]}>
          <Flex justify="space-between" align="center">
            <Flex direction={'column'} gap={1.5} w={'100%'}>
              {/* header */}
              <Text
                fontSize={[null, null, null, '12px', '13px', '14px']}
                fontWeight={'500'}
                color={headerColor}
                lineHeight={1}
              >
                Subscriptions
              </Text>

              <Flex align={'center'} gap={1}>
                {/* premium tag */}
                <SubscriptionTag
                  type="Premium"
                  loading={loading}
                  users={premiumUsers}
                  colorScheme={'yellow'}
                  onClickTag={() => showUsers('premium')}
                />

                {/* teams tag */}
                <SubscriptionTag
                  type="Teams"
                  loading={loading}
                  users={teamsUsers}
                  colorScheme={'pink'}
                  onClickTag={() => showUsers('teams')}
                />

                {/* basic tag */}
                <SubscriptionTag
                  type="Basic"
                  loading={loading}
                  users={basicUsers}
                  colorScheme={'blue'}
                  onClickTag={() => showUsers('basic')}
                />
              </Flex>
            </Flex>

            {/* icon */}
            <Box
              display={[null, null, null, 'none', 'initial', 'initial']}
              bg={colors.highlight['primary']}
              p={2}
              borderRadius="md"
            >
              <AiFillCreditCard size={'16px'} color={iconColor} />
            </Box>
          </Flex>
        </CardBody>
      </Card>
    </SimpleGrid>
  );
}

interface SubscriptionTagProps {
  type: string;
  loading: boolean;
  users: User[];
  colorScheme: string;
  onClickTag: () => void;
}

function SubscriptionTag({
  type,
  loading,
  users,
  colorScheme,
  onClickTag,
}: SubscriptionTagProps) {
  return (
    <Tag
      size={'sm'}
      variant={'solid'}
      colorScheme={colorScheme}
      cursor={'pointer'}
      w={'fit-content'}
      display={'flex'}
      alignItems={'center'}
      gap={1}
      onClick={onClickTag}
    >
      {loading ? (
        <Spinner size="xs" />
      ) : (
        <Text
          lineHeight={1}
          fontSize={[null, null, null, '10px', '10px', '12px']}
        >
          {users?.length}
        </Text>
      )}
      <Text
        lineHeight={1}
        fontSize={[null, null, null, '10px', '10px', '12px']}
      >
        {type}
      </Text>
    </Tag>
  );
}
