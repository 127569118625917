// Password rules
export function isPasswordValid(pass1: string, pass2: string) {
  if (pass1 !== pass2) {
    return "Passwords do not match";
  } else if (pass1.length < 8) {
    return "Password should be 8 characters long.";
  } else if (!containsUppercase(pass1)) {
    return "Password should contain at least one uppercase letter.";
  } else if (!hasLowercase(pass1)) {
    return "Password should contain at least one lowercase letter.";
  } else if (!containsNumber(pass1)) {
    return "Password should contain at least one number.";
  } else if (!containsSpecialCharacter(pass1)) {
    return "Password should contain at least one special character.";
  } else {
    return "";
  }
}

function containsUppercase(str: string) {
  for (let i = 0; i < str.length; i++) {
    if (str[i] !== str[i].toLowerCase()) {
      return true; // Character at index i is an uppercase letter
    }
  }
  return false; // No uppercase letters found in the string
}

function hasLowercase(str: string) {
  // Define a regular expression pattern to match lowercase letters
  var lowercasePattern = /[a-z]/;

  // Iterate through each character in the string
  for (var i = 0; i < str.length; i++) {
    // Check if the current character matches the lowercase pattern
    if (lowercasePattern.test(str[i])) {
      return true; // If a lowercase letter is found, return true
    }
  }

  // If no lowercase letter is found, return false
  return false;
}

function containsNumber(str: string) {
  const regex = /\d/; // Regex to matche any digit (number)
  return regex.test(str);
}

function containsSpecialCharacter(str: string) {
  const regex = /[^a-zA-Z0-9]/; // Regex to match any character that is not a letter or a number
  return regex.test(str);
}
