import { Flex, Icon, Text, Th } from '@chakra-ui/react';
import { useState } from 'react';
import { RxCaretSort } from 'react-icons/rx';

interface ColHearderProps {
  header: string;
  onSort: (header: 'name' | 'type' | 'status' | 'invited on') => void;
  w?: string | number;
  highlighted?: boolean;
}

function ColHeader({ header, onSort, w, highlighted }: ColHearderProps) {
  const [showSortIcon, setShowSortIcon] = useState(false);

  const handleSort = () => {
    const l_header = header.toLowerCase();

    // Toggle sorting direction on header clicked
    if (onSort) {
      onSort(l_header as 'name' | 'type' | 'status' | 'invited on');
    }
  };

  return (
    <Th
      bg={highlighted ? 'gray.100' : 'transparent'}
      color={'gray.500'}
      cursor={'pointer'}
      onMouseEnter={() => setShowSortIcon(true)}
      onMouseLeave={() => setShowSortIcon(false)}
      onClick={handleSort}
      w={w}
    >
      <Flex gap={0.5} align={'center'} w={'fit-content'} minW={'100px'}>
        <Text>{header}</Text>
        {(showSortIcon || highlighted) && (
          <Icon
            as={RxCaretSort}
            color={'gray.600'}
            boxSize={'16px'}
            my={'auto'}
          />
        )}
      </Flex>
    </Th>
  );
}

export default ColHeader;
