import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

// icons
import { AiFillHome } from "react-icons/ai";
import { BsPeopleFill } from "react-icons/bs";

// Initial navigation history state
const initNavHistory = sessionStorage.getItem('navigationHistory');
const initNavHistoryState: string[] = initNavHistory
  ? JSON.parse(initNavHistory)
  : [];
const pathsToBeTracked = ['dashboard', 'clients'];

const pushPathToNavigationHistory = (
  path: string,
  prevNavHistoryState: string[]
) => {
  if (pathsToBeTracked.every((r: string) => !path.startsWith(`/${r}`)))
    return prevNavHistoryState;
  if (prevNavHistoryState.slice(-1)[0] !== path) {
    let newNavHistoryEntries: string[] = [];
    // Clear previous related history
    const root = pathsToBeTracked.find((rt: string) =>
      path.startsWith(`/${rt}`)
    );
    if (root)
      newNavHistoryEntries = prevNavHistoryState.filter(
        (str: string) => !str.includes(`/${root}`)
      );
    newNavHistoryEntries =
      newNavHistoryEntries.length >= 40
        ? [...newNavHistoryEntries.slice(1), path]
        : [...newNavHistoryEntries, path];
    sessionStorage.setItem(
      'navigationHistory',
      JSON.stringify(newNavHistoryEntries)
    );
    return newNavHistoryEntries;
  } else {
    return prevNavHistoryState;
  }
};

function useNavigationTabs() {
  const location = useLocation();

  const [historyEntries, setHistoryEntries] =
    useState<string[]>(initNavHistoryState);

  interface TabsNavigationHandlersProps {
    dashboardTabLink?: string;
    clientsTabLink?: string;
  }

  const tabsNavigationHandlers: TabsNavigationHandlersProps = useMemo(() => {
    const currentPath = `${location.pathname}${location.search}`;
    setHistoryEntries((prevState: string[]) =>
      pushPathToNavigationHistory(currentPath, prevState)
    );

    const links = pathsToBeTracked.reduce((acc, tab) => {
      const latestRelatedHistory = [...historyEntries]
        .reverse()
        .find(entry => entry.startsWith(`/${tab}`));

      const linkValue = latestRelatedHistory
        ? latestRelatedHistory === currentPath
          ? `/${tab}`
          : latestRelatedHistory
        : `/${tab}`; // Default to tab root if no related history

      return {
        ...acc,
        [`${tab}TabLink`]: linkValue,
      };
    }, {});
    return links;
  }, [location.pathname, location.search, historyEntries]);

  const navItems = useMemo(() => {
    const { dashboardTabLink, clientsTabLink }: TabsNavigationHandlersProps =
      tabsNavigationHandlers;
    return [
      {
        name: "dashboard",
        icon: AiFillHome,
        link: dashboardTabLink,
        tooltip: "Dashboard",
      },
      {
        name: 'clients',
        icon: BsPeopleFill,
        link: clientsTabLink,
        tooltip: 'Users',
      },
    ];
  }, [tabsNavigationHandlers]);

  return { navItems };
}

export default useNavigationTabs;
