import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Modal,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Flex,
  useBreakpointValue,
  useColorModeValue,
  InputRightElement,
  Box,
  Text,
} from '@chakra-ui/react';

import UserDetails from 'components/clients/modals/UserDetails';
import InviteNewUser from 'components/clients/modals/InviteNewUser';
import EditUserInfo from 'components/clients/modals/EditUserInfo';
import DeleteUser from 'components/clients/modals/DeleteUser';
import { maxHeight } from 'utils/responsive';
import { CustomScrollBar } from 'components/ui/CustomScrollBar';
import { SingleValue } from 'react-select';
import FirstLastLogin from './table/FirstLastLogin';
import UserType from './table/UserType';
import UserName from './table/UserName';
import ActionButtons from './table/ActionButtons';
import UserStatus from './table/UserStatus';
import InvitedAt from './table/InvitedAt';
import NewUser from './controls/NewUser';
import SearchUsers from './controls/SearchUsers';
import SelectUsers from './controls/SelectUsers';
import StatsUsers from './StatsUsers';
import { SmallCloseIcon } from '@chakra-ui/icons';
import ColHeader from './table/ColHeader';
import { byDate, byName, byStatus, byType, filterUsers } from './helpers';
import TablePagination from './table/TablePagination';
import Loading from 'components/ui/Loading';
import { User } from 'models/Users';

interface UsersProps {
  usersList: User[];
}

export interface ColourOption {
  color: string;
  label: string;
  value: string;
}

export default function UsersTable({ usersList }: UsersProps) {
  const location = useLocation();
  const navigate = useNavigate();

  // States
  const [sortedUsers, setSortedUsers] = useState<User[]>(usersList);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [sortColumn, setSortColumn] = useState<
    'name' | 'type' | 'status' | 'invited on'
  >('invited on');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);

  // Filters States
  const [selectedType, setSelectedType] = useState<ColourOption | null>(null);
  const [input, setInput] = useState('');

  // Modals States
  const [isInviteOpen, setIsInviteOpen] = useState(false); // Invite New User Modal
  const [isUserModalOpen, setIsUserModalOpen] = useState(false); // User Details Modal
  const [isEditOpen, setIsEditOpen] = useState(false); // Update User Info Modal
  const [isDelOpen, setIsDelOpen] = useState(false); // Delete User Modal

  // Ref
  const initialRef = useRef<HTMLInputElement>(null);
  const openInviteModal = () => setIsInviteOpen(true);

  // Theme
  const bg = useColorModeValue('neutral.100', 'dark.700');
  const hoverBg = useColorModeValue('gray.100', 'dark.600');
  const infoColor = useColorModeValue('gray.600', 'gray.400');

  // Pagination
  const ITEMS_PER_PAGE = 9;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + ITEMS_PER_PAGE;
  const currentItems = sortedUsers?.slice(itemOffset, endOffset);
  const pageCount = sortedUsers?.length
    ? Math.ceil(sortedUsers.length / ITEMS_PER_PAGE)
    : 0;
  // Pagination Handler
  const handlePageClick = (event: { selected: number }) => {
    if (sortedUsers?.length === 0) return;

    const newOffset = (event.selected * ITEMS_PER_PAGE) % sortedUsers?.length;
    setItemOffset(newOffset);
  };

  // Sort Handler
  // NOTE: Function to handle sorting when header is clicked
  function handleSort(column: 'name' | 'type' | 'status' | 'invited on') {
    const newSortDirection =
      column === sortColumn && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newSortDirection);
    setSortColumn(column);

    let sorted: User[] = [];

    if (column === 'name') {
      const hasNames = sortedUsers?.filter((u: User) => u?.given_name);
      const hasNoNames = sortedUsers?.filter((u: User) => !u?.given_name);

      let hasNamesSorted = [...hasNames].sort(byName);

      if (newSortDirection === 'desc') hasNamesSorted.reverse();
      sorted = [...hasNamesSorted, ...hasNoNames];
    } else if (column === 'type') sorted = [...sortedUsers].sort(byType);
    else if (column === 'status') sorted = [...sortedUsers].sort(byStatus);
    else if (column === 'invited on') sorted = [...sortedUsers].sort(byDate);

    if (newSortDirection === 'desc' && column !== 'name') sorted.reverse();

    setSortedUsers(sorted);
  }

  // Handlers
  function handleSelectedTypeChange(
    newValue: SingleValue<ColourOption> | null
  ) {
    setSelectedType(newValue);
  }

  function handleClearSelection() {
    setSelectedType(null);
    navigate("/clients?filter=all");
  }

  function handleChange(value: string) {
    setInput(value);
  }

  function clearSearch() {
    setInput('');
  }

  function onClose() {
    setIsUserModalOpen(false);
    setIsInviteOpen(false);
    setIsEditOpen(false);
    setIsDelOpen(false);
  }

  function openUserModal(user: User) {
    setSelectedUser(user);
    setIsUserModalOpen(true);
  }

  function closeUserModal(status: boolean) {
    setSelectedUser(null);
    setIsUserModalOpen(status);
  }

  function openEditModal(user: User) {
    setSelectedUser(user);
    setIsEditOpen(true);
  }

  // Close Edit, Invite or User Details Modal
  function closeModal(status: boolean) {
    setIsInviteOpen(status);
    setIsEditOpen(status);
    setIsUserModalOpen(status);
    setSelectedUser(null);
  }

  function openDeleteModal(USER: User) {
    setIsDelOpen(true);
    setUserToDelete(USER);
  }

  function closeDeleteModal(status: boolean) {
    setIsDelOpen(status);
    setIsUserModalOpen(false);
    const deletedUserId = userToDelete?.id;
    setUserToDelete(null);
    setSelectedUser(null);
    const newUsers = sortedUsers.filter(
      (user: User) => user.id !== deletedUserId
    );
    setSortedUsers(newUsers);
  }

  // Styles
  const UsersHeight = useBreakpointValue(maxHeight);
  const UsersStyle = {
    height: `calc(${UsersHeight} - 190px)`,
    width: '100%',
    margin: '0 auto',
    padding: '6px',
    paddingRight: '12px',
    display: 'flex',
    // 'flex-direction': 'column',
  };

  useEffect(() => {
    // initially and if both filters are cleared
    let filteredUsers = usersList;

    const searchParams = new URLSearchParams(location.search);
    let filter = searchParams.get('filter') ?? 'all';

    // Apply type filter based on URL Filter Params
    if (selectedType === null && filter !== "all") {
      // to apply type filter to all pages
      setItemOffset(0);

      filteredUsers = filterUsers(filteredUsers, filter);
    }
    // Apply type filter based on selectedType
    if (selectedType) {
      // to apply type filter to all pages
      setItemOffset(0);

      const { value } = selectedType;
      filteredUsers = filterUsers(filteredUsers, value);
      navigate(`/clients?filter=${value}`);
    }

    // Apply search filter based on input
    if (input) {
      // to apply search filter to all pages
      setItemOffset(0);

      const searchQuery = input.toLowerCase();
      filteredUsers = filteredUsers.filter(user => {
        const { given_name, family_name, email } = user;
        const l_name = `${given_name?.toLowerCase()} ${family_name?.toLowerCase()}`;
        const l_email = email?.toLowerCase();

        return l_name?.includes(searchQuery) || l_email?.includes(searchQuery);
      });
    }

    setSortedUsers(filteredUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType, input, usersList]);

  if (!usersList || usersList.length === 0) {
    return (
      <Box style={{ ...UsersStyle }}>
        <Flex
          w={'full'}
          h={'full'}
          justify={'center'}
          align={'center'}
          gap={3}
        >
          <Text fontWeight={'600'} color={infoColor} fontSize={'14px'}>
            No users found!
          </Text>
        </Flex>
      </Box>
    );
  }

  return (
    <>
      {/* filters & table  */}
      <Flex direction={'column'}>
        <Flex justify={'space-between'} align={'center'} px={2} py={4}>
          {/* Search and Filter features */}
          <Flex gap={4} align="center" borderRadius={'8px'} w={'100%'}>
            <SearchUsers
              rightComponent={
                !!input && (
                  <InputRightElement>
                    <SmallCloseIcon
                      cursor={'pointer'}
                      onClick={clearSearch}
                      w={4}
                      h={4}
                      color="gray.500"
                      bg={'gray.100'}
                      borderRadius={'50%'}
                    />
                  </InputRightElement>
                )
              }
              value={input}
              onChange={e => handleChange(e.target.value)}
            />
            <SelectUsers
              selected={selectedType}
              onSelectChange={handleSelectedTypeChange}
              onClearSelection={handleClearSelection}
            />
            <StatsUsers allUsers={usersList} filteredUsers={sortedUsers} />
          </Flex>

          {/* Add new user button */}
          <NewUser onAddNewUser={openInviteModal} />
        </Flex>

        {/* added this cond. to prevent initially displayed full-height scroll */}
        {UsersHeight && (
          <Flex direction={'column'} gap={2}>
            <CustomScrollBar style={UsersStyle}>
              <TableContainer boxShadow={'sm'} bg={bg} borderRadius={'8px'}>
                <Table variant="simple">
                  <Thead>
                    <Tr py={3}>
                      <ColHeader
                        header="Name"
                        onSort={handleSort}
                        w={'35%'}
                        highlighted={sortColumn === 'name'}
                      />
                      <ColHeader
                        header="Type"
                        onSort={handleSort}
                        w={'13%'}
                        highlighted={sortColumn === 'type'}
                      />
                      <ColHeader
                        header="Status"
                        onSort={handleSort}
                        w={'13%'}
                        highlighted={sortColumn === 'status'}
                      />
                      <ColHeader
                        header="Invited On"
                        onSort={handleSort}
                        w={'13%'}
                        highlighted={sortColumn === 'invited on'}
                      />
                      <Th color={'gray.500'} w={'13%'}>
                        First & Last Login
                      </Th>
                      <Th color={'gray.500'} w={'13%'}>
                        Actions
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentItems && currentItems?.length > 0 ? (
                      currentItems?.map((user: User, index: number) => (
                        <Tr
                          key={index}
                          _hover={{ bg: hoverBg }}
                          cursor={'pointer'}
                          onClick={() => openUserModal(user)}
                        >
                          <UserName
                            given_name={user?.given_name ?? ''}
                            family_name={user?.family_name ?? ''}
                            email={user?.email ?? ''}
                          />
                          {<UserType type={user.user_type ?? ''} />}
                          <UserStatus
                            status={user?.user_status}
                            enabled={user?.enabled}
                          />
                          <InvitedAt date={user?.invited_at} />
                          <FirstLastLogin
                            first={user?.first_login ?? ''}
                            last={user?.last_login ?? ''}
                          />
                          <ActionButtons
                            user={user}
                            onOpenEditModal={openEditModal}
                            onOpenDeleteModal={openDeleteModal}
                          />
                        </Tr>
                      ))
                    ) : (
                      <Tr>
                        <Td colSpan={6}>
                          <Flex
                            py={20}
                            align={'center'}
                            justify={'center'}
                            fontWeight={'500'}
                            color={'gray.400'}
                          >
                            No matched users
                          </Flex>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </CustomScrollBar>
            <TablePagination
              onPageChange={handlePageClick}
              pageCount={pageCount}
            />
          </Flex>
        )}
      </Flex>

      {/* User's Details */}
      <Modal
        size={'xl'}
        isCentered
        isOpen={isUserModalOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <UserDetails
          selectedUser={selectedUser}
          onClose={() => closeUserModal(false)}
        />
      </Modal>

      {/* Delete Modal */}
      <Modal
        size={'xs'}
        isCentered
        isOpen={isDelOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <DeleteUser
          userToDelete={userToDelete}
          onClose={() => closeDeleteModal(false)}
        />
      </Modal>

      {/* Invite New User Modal */}
      <Modal
        size={'sm'}
        isCentered
        isOpen={isInviteOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        initialFocusRef={initialRef}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <InviteNewUser onClose={() => closeModal(false)} />
      </Modal>

      {/* Update User Info Modal */}
      <Modal
        size={'xs'}
        isCentered
        isOpen={isEditOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <EditUserInfo
          selectedUser={selectedUser}
          onClose={() => closeModal(false)}
        />
      </Modal>
    </>
  );
}
