import useAxiosPrivate from "hooks/useAxiosPrivate";

interface User {
    first_name: string;
    last_name: string;
    email: string;
    user_type?: string;
}

interface Activity {
    user_id?: string;
    activity: string;
}

export const useUsersAPI = () => {
    const axiosPrivate = useAxiosPrivate();

    const getUsers = async () => {
        const response = await axiosPrivate.get(`/admin_dashboard/api/users`);
        return response.data;
    };

    const inviteUser = async (payload: Omit<User, 'user_type'>) => {
        const response = await axiosPrivate.post(`/admin_dashboard/api/users`, payload);
        return response.data;
    };

    const resendInvite = async (payload: { username: string }) => {
        const response = await axiosPrivate.post(`/api/resend_new_password`, payload);
        return response.data;
    };

    const updateUser = async (payload: User, user_sub?: string) => {
        const response = await axiosPrivate.put(`/admin_dashboard/api/users/${user_sub}`, payload);
        return response.data;
    };

    const saveAdminActivity = async (payload: Activity) => {
        const response = await axiosPrivate.post(`/admin_dashboard/api/admin_activities`, payload);
        return response.data;
    };

    const getAdminActivities = async (user_id: string) => {
        const response = await axiosPrivate.get(`/admin_dashboard/api/admin_activities/${user_id}`);
        return response.data;
    };

    const getAllActivities = async () => {
        const response = await axiosPrivate.get(`/admin_dashboard/api/admin_activities`);
        return response.data;
    };

    const disableUser = async (payload: { email: string }) => {
        const response = await axiosPrivate.post(`/admin_dashboard/api/disable_user`, payload);
        return response.data;
    };

    const enableUser = async (payload: { email: string }) => {
        const response = await axiosPrivate.post(`/admin_dashboard/api/enable_user`, payload);
        return response.data;
    };

    const removeUser = async (cognito_sub: string) => {
        const response = await axiosPrivate.delete(`/admin_dashboard/api/users/${cognito_sub}`);
        return response.data;
    };

    const getUserBySub = async (sub: string) => {
        const response = await axiosPrivate.get(`/admin_dashboard/api/users/${sub}`);
        return response.data;
    };


    return {
        getUsers,
        inviteUser,
        resendInvite,
        updateUser,
        saveAdminActivity,
        getAdminActivities,
        getAllActivities,
        disableUser,
        enableUser,
        removeUser,
        getUserBySub
    };
};