import { Tag, Td, useColorModeValue, useTheme } from '@chakra-ui/react';

interface UserTypeProps {
  type: string;
}

export default function UserType({ type }: UserTypeProps) {
  // Theme
  const { colors } = useTheme();
  const noDataBg = useColorModeValue(colors.gray[100], colors.dark[600]);

  const getTheColorScheme = (type: string) => {
    if (type === 'premium') {
      return 'yellow';
    }

    if (type === 'basic') {
      return 'blue';
    }

    if (type === 'teams') {
      return 'pink';
    }

    return "gray";
  }

  return (
    <Td textTransform={'capitalize'} border={'none'} minH={'72px'}>
      {type ? (
        <Tag
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          minW={'66px'}
          size={'sm'}
          variant={'solid'}
          colorScheme={getTheColorScheme(type)}
        >
          {type}
        </Tag>
      ) : (
        <Tag
          bg={noDataBg}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          minW={'66px'}
          size={'sm'}
          color={'gray.400'}
        >
          {'no data'}
        </Tag>
      )}
    </Td>
  );
}
