import { useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  IconButton,
  Text,
  useColorModeValue,
  useTheme,
} from "@chakra-ui/react";

import { FaUserShield } from "react-icons/fa";
import { IoChevronBackOutline } from "react-icons/io5";

export default function Unauthorized() {
  // Theme
  const { colors } = useTheme();
  const hoverBg = useColorModeValue("blue.50", "primaryDark.700");
  const hoverColor = useColorModeValue("blue.400", "blue.300");
  const hoverBorder = useColorModeValue("blue.400", "blue.300");

  // Hooks
  const navigate = useNavigate();
  const handleGoBack = () => navigate(-1);

  return (
    <Flex
      h={"100vh"}
      w={"full"}
      direction={"column"}
      align={"center"}
      justify={"center"}
      gap={"60px"}
    >
      {/* NOTE: assign gradient to icon: custom solution */}
      <svg width="0" height="0">
        <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
          <stop stopColor={colors.gray[700]} offset="0%" />
          <stop stopColor={colors.gray[400]} offset="100%" />
        </linearGradient>
      </svg>

      <IconButton
        aria-label="unauthorized"
        icon={<FaUserShield style={{ fill: "url(#blue-gradient)" }} />}
        bg={"transparent"}
        fontSize={"100px"}
        pointerEvents={"none"}
      />
      <Flex direction={"column"} align={"center"} justify={"center"} gap={3}>
        <Text fontSize={"16px"} color={"gray.500"}>
          It seems you don't have access to the requested page
        </Text>
        <Button
          leftIcon={<IoChevronBackOutline />}
          variant="outline"
          fontSize={"14px"}
          borderColor={"gray.600"}
          color={"gray.600"}
          borderRadius={"20px"}
          transition={"all .2s ease"}
          _hover={{
            textDecoration: "none",
            bg: hoverBg,
            color: hoverColor,
            borderColor: hoverBorder,
            transition: "all .2s ease",
          }}
          _active={{
            textDecoration: "none",
            bg: hoverBg,
            color: hoverColor,
            borderColor: hoverBorder,
            transition: "all .2s ease",
          }}
          onClick={handleGoBack}
        >
          Go Back
        </Button>
      </Flex>
    </Flex>
  );
}
