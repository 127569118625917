import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useUsersAPI } from './useUsersAPI';
import { useToast, Box } from '@chakra-ui/react';
import { errorHandler } from 'utils/helpers';
import { User } from 'models/Users';
import { useSelector } from 'react-redux';
import { selectCurrentAuthData } from 'store/features/auth/authSlice';

export const useResendInvitationMutation = () => {
    const { resendInvite, saveAdminActivity } = useUsersAPI();
    const { user } = useSelector(selectCurrentAuthData);
    const toast = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (USER: User) => {
            const payload = { username: USER.email };
            await resendInvite(payload);
            return USER;
        },
        onSuccess: async (USER) => {
            const activity = {
                user_id: user.id,
                activity: `Resent invitation email to: ${USER.email}`,
            };

            await saveAdminActivity(activity);

            toast({
                position: 'top-right',
                duration: 3000,
                render: () => (
                    <Box
                        color="white"
                        p={3}
                        bg={'highlight.primary'}
                        borderRadius={'6px'}
                    >
                        Invitation email resent successfully
                    </Box>
                ),
            });

            // Update user status
            const updatedUser = { ...USER, user_status: "FORCE_CHANGE_PASSWORD" };

            // Update the user in the cache
            queryClient.setQueryData(['users', USER.id], updatedUser);

            queryClient.refetchQueries({ queryKey: ['users'] })
            queryClient.refetchQueries({ queryKey: ['histories'] })

            return updatedUser;
        },
        onError: (error: any) => {
            toast({
                description: errorHandler(error).message,
                status: 'error',
                position: 'top-right',
            });
        },
    });
};