import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useRefreshToken from "./useRefreshToken";
import { clearCredentials, selectCurrentAuthData, setAccessToken } from "store/features/auth/authSlice";
import { axiosPrivate } from "api/axios";

const useAxiosPrivate = () => {
    const dispatch = useDispatch()
    const { user, accessToken } = useSelector(selectCurrentAuthData)
    const refresh = useRefreshToken()

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            (config) => {
                if (!config.headers["Authorization"]) {
                    config.headers["Authorization"] = `Bearer ${accessToken}`
                }
                return config
            },
            (error) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config
                if (!prevRequest?.sent && (error?.response?.status === 401 || error?.response?.status === 403)) {
                    prevRequest.sent = true;
                    const ref = await refresh();
                    if (ref) {
                        const { accessToken: newAccessToken } = ref
                        dispatch(setAccessToken(newAccessToken))
                        prevRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                        return axiosPrivate(prevRequest)
                    }
                    dispatch(clearCredentials())
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [user, accessToken, dispatch])

    return axiosPrivate;
}

export default useAxiosPrivate;