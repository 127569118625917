import { Td, useColorModeValue } from '@chakra-ui/react';
import { format } from 'date-fns';

interface InvitedAtProps {
  date: string | number;
}

export default function InvitedAt({ date }: InvitedAtProps) {
  const grayColor = useColorModeValue('gray.600', 'gray.200');

  return (
    <Td color={grayColor} fontSize="12px" border={'none'} minH={'72px'}>
      {format(new Date(date), 'MMMM d, yyyy')}
    </Td>
  );
}
