export const colors = {
    primary: {
        100: "#F5FEFF",
        200: "#B0DBDD",
        300: "#6DAFB3",
        400: "#367D80",
        500: "#156064",
        600: "#06575B",
        700: "#004044",
        800: "#003033",
        900: "#001618",
    },
    secondary: {
        100: "#D5EEFF",
        200: "#A2CEEB",
        300: "#66AAD8",
        400: "#4196CE",
        500: "#1A86CE",
        600: "#166AA2",
        700: "#0D4C76",
        800: "#093654",
        900: "#041B2A",
    },

    neutral: {
        100: "#ffffff",
        200: "#F5F5F5",
        300: "#EAEAEA",
        400: "#E0E0E0",
        500: "#D6D6D6",
        600: "#CACACA",
        700: "#BEBEBE",
        800: "#B1AFAF",
        900: "#696969",
    },
    dark: {
        400: "#1E1502",
        500: "#305768",
        600: "#193e4e",
        700: "#0b2e3e",
        800: "#042230",
        900: "#031d2a",
    },

    highlight: {
        primary: "#00C1B4",
        neutral: {
            100: "#F3F3F7",
            200: "#EBF0F9",
        },
    },

    gradient: {
        100: "#F3F3F7",
        200: "#EBF0F9",
    },

    none: "#D9594C",
    low: "#66AAD8",
    middle: "#367D80",
    high: "#198B7C",

    cosmiclatte: "#FFF6E1",
    gold: "#DDB967",
    bronze: "#DD8232",
    jasper: "#D9594C",
    caribean: "#156064",
};