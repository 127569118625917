import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

export interface User {
    email: string;
    email_verified: boolean;
    family_name?: string;
    given_name?: string;
    sub: string;
    id: string;
    user_type: string;
    role: string;
    permissions: string[];
    user_wallet: {
        currency: string;
        is_yearly: boolean;
        plan: string;
        subscription_id: string;
        updated_on: Date;
        wallet: number;
    },
    team_id: string | undefined
}

export interface AuthState {
    user: User | null;
    accessToken: string;
    refreshToken: string;
}


const ref = localStorage.getItem("refreshToken");
let refreshToken = '';

if (ref) {
    try {
        refreshToken = JSON.parse(ref);
    } catch (error) {
        localStorage.removeItem("refreshToken");
    }
}

const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: null, accessToken: '', refreshToken
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
            localStorage.setItem("loggedIn", JSON.stringify(true));
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = action.payload
            localStorage.setItem("refreshToken", JSON.stringify(action.payload));
        },
        clearCredentials: (state) => {
            state.user = null;
            state.accessToken = '';
            state.refreshToken = '';
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("loggedIn");
        },
        updateUserAttributes: (state, action) => {
            const newUserAttributes = action.payload;
            const prevAttr = state.user || {};
            state.user = { ...prevAttr, ...newUserAttributes };
        },
    },
});

export const { setUser,
    clearCredentials,
    updateUserAttributes,
    setAccessToken,
    setRefreshToken } = authSlice.actions;

export default authSlice.reducer;

const selectAuthState = (state: { auth: AuthState }) => state.auth;

export const selectCurrentAuthData = createSelector(
    [selectAuthState],
    (auth: any) => ({
        user: auth.user,
        accessToken: auth.accessToken,
        refreshToken: auth.refreshToken
    })
);
