import { useMemo } from 'react';
import { chakra, useTheme } from '@chakra-ui/react';
import ReactPaginate from 'react-paginate';

interface TablePaginationProps {
  onPageChange: any;
  pageCount: any;
}

function TablePagination({ onPageChange, pageCount }: TablePaginationProps) {
  const { colors } = useTheme();

  const StyledReactPaginate = useMemo(
    () =>
      chakra(ReactPaginate, {
        baseStyle: {
          listStyle: 'none',
          display: 'flex',
          gap: '10px',
          alignSelf: 'flex-end',
          marginRight: '16px',

          li: {
            outline: `1px solid ${colors.secondary[300]}`,
            borderRadius: '8px',
            width: '24px',
            height: '24px',
            lineHeight: '1',
            fontSize: '12px',
            cursor: 'pointer',

            a: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              borderRadius: '8px',
            },

            '&:hover, &.selected': {
              outline: 'none',

              a: {
                backgroundColor: colors.secondary[400],
                color: colors.neutral[100],
              },
            },

            '&.disabled': {
              pointerEvents: 'none',
              outline: `1px solid ${colors.neutral[400]}`,
              color: colors.neutral[400],
            },
          },
        },
      }),
    [colors]
  );

  return (
    <StyledReactPaginate
      breakLabel="..."
      nextLabel=">"
      onPageChange={onPageChange}
      pageRangeDisplayed={5}
      pageCount={pageCount}
      previousLabel="<"
      renderOnZeroPageCount={null}
    />
  );
}

export default TablePagination;
