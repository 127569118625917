import { useState } from 'react';
import {
  Button,
  Flex,
  Heading,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { User } from 'models/Users';
import { useDeleteUserMutation } from 'api/Users/useDeleteUserMutation';

// Delete User Props
interface DelUserProps {
  userToDelete: User | null;
  onClose: (status: boolean) => void;
}

export default function DeleteUser({ userToDelete, onClose }: DelUserProps) {
  const deleteUserMutation = useDeleteUserMutation();

  const [isDeleting, setIsDeleting] = useState(false);
  const grayColor = useColorModeValue('gray.400', 'gray.500');

  const handleRemoveUser = async () => {
    if (!userToDelete) return
    setIsDeleting(true);

    let USER = { email: userToDelete?.email, cognito_sub: userToDelete?.cognito_sub }

    deleteUserMutation.mutate(USER, {
      onSettled: () => {
        setIsDeleting(false);
        onClose(false);
      },
    });
  };

  return (
    <ModalContent>
      <ModalHeader></ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Heading fontWeight={700} fontSize={'18px'} mt={2}>
          Are you sure you want to remove this user?
        </Heading>
        <Text
          fontSize="16px"
          color={grayColor}
          fontWeight={700}
          lineHeight={1}
          py={2}
        >
          {userToDelete?.given_name} {userToDelete?.family_name}
        </Text>
        <Text fontSize="14px" color={grayColor} lineHeight={1}>
          {userToDelete?.email}
        </Text>
        <Flex flexDirection="row" mt={2}>
          <Text fontSize="14px" color={grayColor} lineHeight={1}>
            User Type:
          </Text>
          <Text
            pl={1}
            lineHeight={1}
            fontSize="16px"
            fontWeight={700}
            color={grayColor}
            textTransform={'capitalize'}
          >
            {userToDelete?.user_type}
          </Text>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button
          mr={3}
          size={'sm'}
          colorScheme="red"
          isLoading={isDeleting}
          loadingText="Deleting"
          onClick={() => handleRemoveUser()}
        >
          Delete Permanently
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}
