import { InfoIcon } from '@chakra-ui/icons';
import {
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { getDayOneMonthAgo } from 'utils/helpers';

function OverviewInfo() {
  const color = useColorModeValue('blue.600', 'dark.500');
  const arrowBg = useColorModeValue('blue.700', 'dark.700');
  const borderRightColor = useColorModeValue('secondary.400', 'neutral.400');
  const bgGradient = useColorModeValue(
    'linear(to-r,secondary.700, secondary.600)',
    'linear(to-r,dark.700, dark.600)'
  );

  return (
    <Popover placement="start" closeOnBlur={false} trigger="hover">
      <PopoverTrigger>
        <Icon
          mb={'2px'}
          boxSize={[null, null, null, 3, 3.5, 4]}
          color={color}
          as={InfoIcon}
          cursor={'pointer'}
        />
      </PopoverTrigger>
      <PopoverContent
        borderColor={'dark.700'}
        bgGradient={bgGradient}
        color={'neutral.200'}
        minW={'480px'}
      >
        <PopoverBody>
          <PopoverArrow bg={arrowBg} />
          <PopoverBody fontSize={'12px'} letterSpacing={'0.02rem'}>
            <Flex w={'100%'} gap={2} align={'flex-start'}>
              <Text
                w={'60%'}
                borderRightColor={borderRightColor}
                borderRightWidth={1}
                mr={2}
                pr={2}
                fontWeight={'500'}
              >
                <Text as={'span'} fontWeight={'bold'}>
                  New Users:
                </Text>{' '}
                <Text as={'span'} opacity={0.9}>
                  Added within the past 30 days, starting from{' '}
                  {getDayOneMonthAgo()} until today
                </Text>
              </Text>
              <Text w={'40%'} fontWeight={'500'}>
                <Text as={'span'} fontWeight={'bold'}>
                  Active Users:
                </Text>{' '}
                <Text as={'span'} opacity={0.9}>
                  Logged in at least once in the past week
                </Text>
              </Text>
            </Flex>
          </PopoverBody>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default OverviewInfo;
