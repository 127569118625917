import { Flex, Td, Text, useColorModeValue } from '@chakra-ui/react';
import { format } from 'date-fns';

interface FirstLastLoginProps {
  first: string;
  last: string;
}

export default function FirstLastLogin({ first, last }: FirstLastLoginProps) {
  const grayColor = useColorModeValue('gray.600', 'gray.200');

  return (
    <Td color={grayColor} fontSize="14px" border={'none'} minH={'72px'}>
      {first ? (
        <Flex gap={1}>
          <Text fontWeight={'500'}>First:</Text>
          <Text as={'span'} fontSize="12px">
            {format(new Date(first), "MMM d, yyyy 'at' hh:mma")}
          </Text>
        </Flex>
      ) : (
        <Text fontSize="12px">Not yet logged in</Text>
      )}

      {last && (
        <Flex gap={1}>
          <Text fontWeight={'500'}>Last:</Text>
          <Text as={'span'} fontSize="12px">
            {format(new Date(last), "MMM d, yyyy 'at' hh:mma")}
          </Text>
        </Flex>
      )}
    </Td>
  );
}
