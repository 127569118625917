const HOST_URL = window.location.protocol + "//" + window.location.hostname;
const MOLECULELAKE_DATA_URL = 'https://mdps.vitafluence.com';
export const environment = {
  // BACKEND_API:
  BACKEND_API:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000"
      : `${HOST_URL}`,
  MOLECULELAKE_DATA_API:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8080"
      : `${MOLECULELAKE_DATA_URL}`
};